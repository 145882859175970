<div class="modal-header">
  <div *ngIf="showCloseButton" class="modal-header-x" (click)="bsModalRef.hide()">
    <i-feather name="x"></i-feather>
  </div>
</div>
<div *ngIf="globalVars.loggedInUser; else elseBlock">
  <buy-deso (showCloseButton)="showCloseButton = $event" (closeModal)="bsModalRef.hide()" [isModal]="true" [activeTabInput]="activeTabInput"></buy-deso>
</div>
<ng-template #elseBlock>
  <buy-deso-logged-out></buy-deso-logged-out>
</ng-template>
