<div class="position-relative creator-profile__container creator-profile__container-cover" [style.--cover-image]="getCoverImageUrl()" style="background-repeat: no-repeat; background-size: cover">
  <div class="d-flex js-creator-profile-top-card-container creator-profile__top-card-profile-row">
    <div
      [ngClass]="{
        nft__avatar: profile?.ExtraData?.NFTProfilePictureUrl
      }"
      class="creator-profile__avatar position-relative"
      [avatar]="profile.PublicKeyBase58Check"
      [nftProfileUrl]="profile?.ExtraData?.NFTProfilePictureUrl"
    >
    </div>
    <div
      [ngClass]="{
        nft__avatar: profile?.ExtraData?.NFTProfilePictureUrl
      }"
      class="creator-profile__avatar-border"
    >
    </div>
    <div class="d-flex flex-column creator-profile__username-price">
      <div class="fs-24px font-weight-bold d-flex align-items-center">
        @{{ profile.Username }}

        <div
          *ngIf="profile.IsReserved && !profile.IsVerified"
          (click)="tooltip.toggle()"
          class="ml-10px cursor-pointer fs-18px lh-12px fc-muted"
          matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
          [matTooltip]="'This profile is reserved'"
          #tooltip="matTooltip"
        >
          <i class="far fa-clock fa-md align-middle"></i>
        </div>

        <div
          *ngIf="profile.IsVerified"
          (click)="tooltip.toggle()"
          class="ml-1 mb-1 cursor-pointer fs-20px text-primary"
          matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
          [matTooltip]="'This account is verified'"
          #tooltip="matTooltip"
        >
          <i-feather name="check-circle" class="feather-medium"></i-feather>
        </div>
      </div>
      <div *ngIf="profile.CoinPriceDeSoNanos > 0" style="whitespace: nowrap" class="d-flex">
        <div class="font-weight-bold" style="display: inline">
          ≈{{ globalVars.nanosToUSD(profile.CoinPriceDeSoNanos, 2) }}
          <span class="fc-muted font-weight-normal">{{ 'creator_profile_top_card.coin_price' | transloco }}</span>
        </div>
      </div>
      <div *ngIf="profile?.ExtraData?.NFTProfilePicturePostHashHex">
        <a [routerLink]="['/' + globalVars.RouteNames.NFT, profile?.ExtraData?.NFTProfilePicturePostHashHex]" class="fs-14px fc-secondary"><u>View NFT</u></a>
      </div>
    </div>
    <div class="creator-profile__update-buy">
      <div
        *ngIf="showElipsisDropdownMenu()"
        class="fs-15px pr-15px d-inline"
        dropdown
        container="body"
      >
        <a class="link--unstyled text-grey9 link--unstyled text-grey9" dropdownToggle>
          <i class="fas fa-ellipsis-h"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right bc-dropdown-menu p-0 border fs-15px" *dropdownMenu>
          <div>

            <a
              class="dropdown-menu-item d-block link--unstyled p-10px feed-post__dropdown-menu-item"
              (click)="messageUser()"
              *ngIf="!profileBelongsToLoggedInUser()"
            >
              {{ 'creator_profile_top_card.message_user' | transloco }}
            </a>
            <a
              class="dropdown-menu-item d-block link--unstyled p-10px feed-post__dropdown-menu-item"
              (click)="_copyPublicKey()"
            >
              Copy User's Public Key
            </a>
            <a
              class="dropdown-menu-item d-block link--unstyled p-10px feed-post__dropdown-menu-item"
              (click)="globalVars.hasUserBlockedCreator(profile.PublicKeyBase58Check) ? unblock() : block()"
              *ngIf="!profileBelongsToLoggedInUser()"
            >
              {{ globalVars.hasUserBlockedCreator(profile.PublicKeyBase58Check) ? 'creator_profile_top_card.unblock' : 'creator_profile_top_card.block' | transloco }} {{ 'creator_profile_top_card.user' | transloco }}
            </a>
            <a
              class="dropdown-menu-item d-block link--unstyled p-10px feed-post__dropdown-menu-item"
              (click)="reportUser()"
              *ngIf="!profileBelongsToLoggedInUser()"
            >
              {{ 'creator_profile_top_card.report_user' | transloco }}
            </a>
            <a
              class="dropdown-menu-item d-block link--unstyled p-10px feed-post__dropdown-menu-item"
              (click)="updateWellKnownCreatorsList()"
              *ngIf="!profile.IsFeaturedTutorialUpAndComingCreator && globalVars.showSuperAdminTools()"
            >
              {{ profile.IsFeaturedTutorialWellKnownCreator ? 'creator_profile_top_card.remove' : 'creator_profile_top_card.add' | transloco }} {{ 'creator_profile_top_card.user' | transloco }} {{ 'creator_profile_top_card.wellknown_creator' | transloco }}
            </a>
            <a
              class="dropdown-menu-item d-block link--unstyled p-10px feed-post__dropdown-menu-item"
              (click)="updateUpAndComingCreatorsList()"
              *ngIf="!profile.IsFeaturedTutorialWellKnownCreator && globalVars.showSuperAdminTools()"
            >
              {{ profile.IsFeaturedTutorialUpAndComingCreator ? 'creator_profile_top_card.remove' : 'creator_profile_top_card.add' | transloco }} {{ 'creator_profile_top_card.user' | transloco }} {{ 'creator_profile_top_card.upcoming_creator' | transloco }}
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="globalVars.loggedInUser" class="d-inline-block fs-15px creator-profile-top-card__edit-btn">
        <div *ngIf="profileBelongsToLoggedInUser(); else elseBlock">
          <!-- Show update profile link -->
          <a
            (click)="openModal($event)"
          >
            <i-feather name="settings"></i-feather>
            {{ 'creator_profile_top_card.edit' | transloco }}
          </a>
        </div>
        <ng-template #elseBlock>
          <!-- Show follow button-->
          <follow-button
            *ngIf="!globalVars.hasUserBlockedCreator(profile.PublicKeyBase58Check)"
            [displayAsLink]="true"
            [followedPubKeyBase58Check]="profile.PublicKeyBase58Check"
          ></follow-button>
        </ng-template>
      </div>
      <a
        class="btn creator-profile-top-card__buy-btn"
        (click)="openBuyCreatorCoinModal($event)"
        *ngIf="!globalVars.isMobile() && (getFoundersRewardPercent() < 100 || profile.PublicKeyBase58Check === globalVars?.loggedInUser?.PublicKeyBase58Check)"
      >
        {{ 'creator_profile_top_card.buy' | transloco }}
      </a>
      <a
        class="btn btn-danger font-weight-bold ml-15px fs-14px creator-profile-top-card__block-btn"
        *ngIf="globalVars.hasUserBlockedCreator(profile.PublicKeyBase58Check)"
        (click)="unblock()"
      >
        <span>{{ 'creator_profile_top_card.unblock' | transloco }}</span>
      </a>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-end pt-15px mr-15px js-creator-profile-top-card-container">

  </div>

  <div class="d-flex flex-column">
    <div class="fs-15px py-15px text-break" [innerHTML]="profile.Description | sanitizeAndAutoLink"></div>
    <div class="creator-profile__divider-border"></div>
    <div class="fs-15px d-flex flex-column flex-wrap">
      <div
        class="d-flex justify-content-between"
        [ngClass]="{ 'flex-column align-items-center': globalVars.isMobile() }"
      >
        <div class="d-flex justify-content-center" [ngClass]="{ 'justify-content-between': globalVars.isMobile() }">
          <a
            class="link--unstyled"
            *ngIf="followerCount != null"
            [routerLink]="AppRoutingModule.userFollowersPath(profile.Username)"
            queryParamsHandling="merge"
          >
            <span class="font-weight-bold">{{ followerCount }}&nbsp;</span>
            <span class="fc-muted">{{ 'creator_profile_top_card.followers' | transloco }}&nbsp;&nbsp;&nbsp;</span>
          </a>

          <a
            class="link--unstyled"
            *ngIf="followingCount != null"
            [routerLink]="AppRoutingModule.userFollowingPath(profile.Username)"
            queryParamsHandling="merge"
          >
            <span class="font-weight-bold">{{ followingCount }}&nbsp;</span>
            <span class="fc-muted">{{ 'creator_profile_top_card.following' | transloco }}&nbsp;&nbsp;&nbsp;</span>
          </a>
          <div>
            <span class="font-weight-bold">&nbsp;{{ getFoundersRewardPercent() | number: "0.0-9" }}% </span>
            <span class="fc-muted">FR</span>
          </div>
        </div>
        <div *ngIf="profile?.Username" [ngClass]="{ 'mt-10px': globalVars.isMobile() }">
          <a class="fc-blue" [href]="getUserDetailsLink()">
            View Activity
          </a>
        </div>
      </div>
      <button
        class="primary-button w-100 mt-20px"
        (click)="openBuyCreatorCoinModal($event)"
        *ngIf="globalVars.isMobile()"
      >
        Buy
      </button>

    </div>
  </div>
</div>
