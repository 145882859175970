<div
  class="cursor-pointer background-color-light-grey border-bottom"
  [ngStyle]="{
    'font-size': fontSize + 'px',
    'border-radius': borderRadiusSize + 'px',
    'font-weight': fontWeight
  }"
  (click)="navigateToURL()"
>
    <div
      class="d-flex align-items-center"
      [ngClass]="{
      'justify-content-between': !(justifyLeft || justifyAround),
      'justify-left': justifyLeft,
      'justify-around': justifyAround,
      'px-15px py-5px': padding
    }">
      <div *ngIf="confetti">🎉</div>
      <div [ngClass]="{ 'd-flex': !globalVars.isMobile()} ">
        <div *ngIf="timerText">
          {{ timerText }}&nbsp;&nbsp;
        </div>
        <div class="font-weight-semibold">
          <b>
          {{ days + "d " + hours + "h " + minutes + "m " + seconds + "s" }}
          </b>
        </div>
      </div>
      <div *ngIf="confetti">🎉</div>
    </div>
</div>
