<div *ngIf="isLoadingEditModel" class="mt-5">
  <simple-center-loader [height]="200"></simple-center-loader>
</div>
<div *ngIf="!isLoadingEditModel" class="create-long-post__form-container">
  <form (submit)="submit($event)" (input)="onFormInput($event)">
    <div class="create-long-post__form-header">
      <h3><strong>Create Blog Post</strong></h3>
      <div class="create-long-post__primary-actions">
        <button
          *ngIf="this.editPostHashHex"
          type="button"
          class="ml-auto btn-secondary mr-2 rounded editor-publish-button"
          (click)="location.back()"
        >
          <span *ngIf="!this.isSubmittingPost">Cancel</span>
        </button>
        <button
          class="ml-auto btn-primary rounded editor-publish-button"
          type="submit"
          [disabled]="this.isSubmittingPost"
        >
          <span *ngIf="this.isSubmittingPost"><i class="fas fa-spinner fa-spin ml-5px"></i></span>
          <span *ngIf="!this.isSubmittingPost">Publish</span>
        </button>
      </div>
    </div>
    <div class="d-flex align-items-start p-4 pb-0" [ngStyle]="{ paddingBottom: 0 }">
      <div [ngStyle]="{ 'flex-grow': 1 }">
        <input
          #titleInput
          [required]="true"
          [(ngModel)]="model.Title"
          name="blogTitle"
          type="text"
          placeholder="Headline..."
          class="mb-2 p-2 px-3 rounded font-weight-bold fs-24px"
          autocomplete="off"
          autofocus="true"
        />
        <input
          [(ngModel)]="model.Description"
          name="blogTagline"
          type="text"
          placeholder="Description..."
          class="rounded p-2 px-3"
          autocomplete="off"
        />
      </div>
    </div>
    <div class="pl-4 pr-4 pb-4" [ngStyle]="{ paddingTop: 0 }">
      <div
        (drop)="onDropImg($event)"
        (dragover)="onDragOver($event)"
        (dragenter)="onDragEnter($event)"
        (dragleave)="onDragLeave($event)"
        class="border rounded text-center d-flex justify-content-center align-items-center position-relative bg-light"
        tabindex="0"
        [ngStyle]="{
          height: !coverImgSrc ? '300px' : 'auto',
          width: '100%',
          overflow: 'hidden'
        }"
      >
        <input #coverImgInput type="file" (change)="onFileSelected($event)" accept="image/*" hidden />
        <div *ngIf="coverImgSrc" class="position-absolute p-2" [ngStyle]="{ top: 0, right: 0 }">
          <button
            type="button"
            class="rounded-circle mr-1 p-2"
            (click)="onClickSelectFile($event)"
            aria-label="Change cover image"
          >
            <i-feather name="edit-2"></i-feather>
          </button>
          <button
            type="button"
            class="rounded-circle p-2"
            (click)="onRemoveCoverImg($event)"
            aria-label="Remove cover image"
          >
            <i-feather name="x"></i-feather>
          </button>
        </div>
        <img *ngIf="coverImgSrc" [src]="coverImgSrc" class="w-100" />
        <span *ngIf="!coverImgSrc">
          <p class="w-60 mb-3" [ngStyle]="{ margin: '0 auto' }">
            Add a cover image that compliments your blog post. Drag a file into this area or select a file from your
            computer.
          </p>
          <button
            type="button"
            [ngClass]="{ 'btn-success': isDraggingFileOverDropZone }"
            (click)="onClickSelectFile($event)"
            class="ml-auto btn-primary rounded editor-publish-button"
          >
            Select File
          </button>
        </span>
      </div>
    </div>
  </form>
  <div class="editor-content" [ngStyle]="{ minHeight: '500px' }">
    <quill-editor
      [required]="true"
      [modules]="quillModules"
      [(ngModel)]="model.ContentDelta"
      format="object"
      [placeholder]="placeholder | transloco"
      (onContentChanged)="onContentChange($event)"
      [formats]="[
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'header',
        'link',
        'image',
        'blockquote',
        'code-block',
        'script',
        'indent',
        'mention'
      ]"
    ></quill-editor>
  </div>
</div>
