import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-get-starter-deso-page",
  templateUrl: "./get-starter-deso-page.component.html",
  styleUrls: ["./get-starter-deso-page.component.scss"],
})
export class GetStarterDeSoPageComponent {
  constructor() {}
}
