<div class="row no-gutters py-15px create-nft-auction-row-border d-flex align-items-center" style="height: 56px">
  <div
    [ngClass]="{ 'col-3': columnCount() === 2, 'col-5': columnCount() === 1 }"
    class="mb-0px cursor-pointer"
    (click)="updateBidSort(SN_FIELD)"
  >
    Number
    <i-feather
      *ngIf="sortByField === SN_FIELD"
      class="feather-small"
      name="{{ sortByOrder === 'asc' ? 'arrow-down' : 'arrow-up' }}"
    ></i-feather>
  </div>
  <div *ngIf="includeColumn('high')" class="col-4 mb-0px cursor-pointer" (click)="updateBidSort(HIGH_BID_FIELD)">
    {{ columns.high }}
    <i-feather
      *ngIf="sortByField === HIGH_BID_FIELD"
      class="feather-small"
      name="{{ sortByOrder === 'asc' ? 'arrow-down' : 'arrow-up' }}"
    ></i-feather>
  </div>
  <div *ngIf="includeColumn('min')" class="col-5 mb-0px cursor-pointer" (click)="updateBidSort(MIN_BID_FIELD)">
    {{ columns.min }}
    <i-feather
      *ngIf="sortByField === MIN_BID_FIELD"
      class="feather-small fc-secondary"
      name="{{ sortByOrder === 'asc' ? 'arrow-down' : 'arrow-up' }}"
    ></i-feather>
  </div>
  <div *ngIf="includeColumn('buyNow')" class="col-5 mb-0px cursor-pointer" (click)="updateBidSort(BUY_NOW_PRICE_FIELD)">
    {{ columns.buyNow }}
    <i-feather
      *ngIf="sortByField === BUY_NOW_PRICE_FIELD"
      class="feather-small fc-secondary"
      name="{{ sortByOrder === 'asc' ? 'arrow-down' : 'arrow-up' }}"
    ></i-feather>
  </div>
</div>
<div class="place-bid-rows mb-30px" *ngIf="!buyingNow">
<!--    *ngFor="let nft of sortedSerialNumbers"-->
  <div
    #uiScroll
    *uiScroll="let nft of datasource"
    class="row no-gutters background-color-white py-15px mb-0px create-nft-auction-row-border cursor-pointer"
    (click)="selectSerialNumber(nft.SerialNumber)"
  >
    <div
      [ngClass]="{ 'col-3': columnCount() === 2, 'col-5': columnCount() === 1 }"
      class="mb-0px d-flex align-items-center">
      <button
        class="checkbox-circle radio mr-10px"
        [ngClass]="{ checked: selectedSerialNumber?.SerialNumber === nft.SerialNumber }"
      >
        <i-feather name="check"></i-feather>
      </button>
      <span class="lh-15px">#{{ nft.SerialNumber }}</span>
    </div>
    <div *ngIf="includeColumn('high')" class="col-4 mb-0px d-flex align-items-center">
      <div>
        <div class="d-lg-inline-block d-block">{{ globalVars.nanosToDeSo(nft.HighestBidAmountNanos) }} DESO </div>
        <div class="text-grey7 d-lg-inline-block d-block">( ~{{globalVars.nanosToUSD(nft.HighestBidAmountNanos, 2) }})</div>
      </div>
    </div>
    <div *ngIf="includeColumn('min')" class="col-5 mb-0px d-flex align-items-center">
      <div>
        <div class="d-lg-inline-block d-block">{{ globalVars.nanosToDeSo(nft.MinBidAmountNanos) }} DESO </div>
        <div class="text-grey7 d-lg-inline-block d-block">(~{{ globalVars.nanosToUSD(nft.MinBidAmountNanos, 2) }})</div>
      </div>
    </div>
    <div *ngIf="includeColumn('buyNow')" class="col-5 mb-0px d-flex align-items-center">
      <div>
        <div class="d-lg-inline-block d-block">{{ globalVars.nanosToDeSo(nft.BuyNowPriceNanos) }} DESO </div>
        <div class="text-grey7 d-lg-inline-block d-block">(~{{ globalVars.nanosToUSD(nft.BuyNowPriceNanos, 2) }})</div>
      </div>
    </div>
    <div
      *ngIf="includeColumn('secondaryIndicator') && nft.OwnerPublicKeyBase58Check !== nftCreatorPublicKeyBase58Check"
      [ngClass]="{ 'w-100 mt-5px text-center': globalVars.isMobile() || columnCount() > 1 }"
      class="br-10px bg-highlight-secondary fc-blue py-5px px-10px"
    >
      <span>Resale</span>
    </div>
  </div>
  <simple-center-loader *ngIf="buyingNow" [titleLoadingText]="'Processing'"></simple-center-loader>
</div>

