import { Component, OnInit } from "@angular/core";

@Component({
  selector: "transfer-deso-page",
  templateUrl: "./transfer-deso-page.component.html",
  styleUrls: ["./transfer-deso-page.component.scss"],
})
export class TransferDeSoPageComponent {
  constructor() {}
}
