<div class="modal-header" *ngIf="!inTutorial">
  <div class="modal-header-x" (click)="closeModal()">
    <i-feather name="x"></i-feather>
  </div>
</div>

<feed-create-post
  numberOfRowsInTextArea="3"
  [inModal]="true"
  [onCreateBlog]="closeModal.bind(this)"
  [postToEdit]="postToEdit"
  (postCreated)="onPostCreated($event)"
  (postCreated)="bsModalRef.hide()"
  (postUpdated)="postUpdated($event)"
></feed-create-post>
