import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "diamond-tutorial-page",
  templateUrl: "./diamond-tutorial-page.component.html",
  styleUrls: ["./diamond-tutorial-page.component.scss"],
})
export class DiamondTutorialPageComponent {
  constructor() {}
}
