<div class="position-relative" *transloco="let t">
  <div class="w-100 position-absolute left-bar-more-container" #more id="more">
    <div class="d-flex justify-content-end align-items-center pt-16px left-bar-close-button pr-16px">
      <i-feather (click)="_exitMore()" name="x" class="feather-small fs-0px cursor-pointer"></i-feather>
    </div>
    <a
      class="cursor-pointer w-100 d-flex align-items-center ml-16px fc-default left-bar-link"
      style="height: 36px"
      (click)="openSettings()"
    >
      <i-feather name="settings" class="feather-medium fs-0px"></i-feather>
      <a class="fs-17px fc-default font-weight-bold" style="margin-left: 23px">
        {{ "left_bar.settings" | transloco }}
      </a>
    </a>
    <div
      class="cursor-pointer w-100 d-flex align-items-center ml-16px fc-default"
      style="height: 36px"
      (click)="startTutorial()"
      *ngIf="globalVars.loggedInUser.TutorialStatus !== TutorialStatus.COMPLETE"
    >
      <i-feather name="monitor" class="feather-medium fs-0px"></i-feather>
      <a class="fs-17px fc-default font-weight-bold" style="margin-left: 23px">{{ 'left_bar.tutorial' | transloco }}</a>
    </div>
<!--    <div class="ml-16px" *ngIf="globalVars.loggedInUser.ReferralInfoResponses?.length > 0">-->
<!--      <left-bar-button-->
<!--        [link]="'/' + globalVars.RouteNames.REFERRALS"-->
<!--        [buttonLabel]="t('left_bar.referrals')"-->
<!--        [iconName]="'share-2'"-->
<!--      ></left-bar-button>-->
<!--    </div>-->
    <a
      *ngIf="environment.supportEmail"
      class="cursor-pointer w-100 d-flex align-items-center ml-16px fc-default left-bar-link"
      style="height: 36px"
      (click)="logHelp()"
      [href]="getHelpMailToAttr()"
      target="_blank"
    >
      <i-feather name="help-circle" class="feather-medium fs-0px"></i-feather>
      <a class="fs-17px fc-default font-weight-bold" style="margin-left: 23px">{{ "left_bar.help" | transloco }}</a>
    </a>
    <div
    class="cursor-pointer w-100 d-flex align-items-center ml-16px fc-default"
    style="height: 36px">
      <left-bar-button
        *ngIf="globalVars.showAdminTools()"
        [link]="'/' + globalVars.RouteNames.ADMIN"
        [buttonLabel]="t('left_bar.admin')"
        [iconName]="'codesandbox'"
      ></left-bar-button>
  </div>
</div>
</div>
