<div class="d-flex flex-column h-100 messages-thread-view__container">
  <!-- Messages Container -->
  <div class="flex-grow-1" style="overflow: hidden">
    <div
      *ngIf="messageThread"
      class="w-100 p-15px disable-scrollbars"
      style="overflow-y: scroll"
      id="messagesContainer"
      #messagesContainer
    >
      <message
        *ngFor="let message of messageThread.Messages; let ii = index"
        [nextMessage]="ii < messageThread.Messages.length - 1 ? messageThread.Messages[ii + 1] : null"
        [message]="message"
        [profile]="messageThread.ProfileEntryResponse"
      ></message>
      <div *ngIf="!globalVars.isMobile()">
        <div class="d-lg-none d-block global__bottom-bar-mobile-height mb-5px"></div>
        <div class="d-lg-block d-none global__top-bar__height"></div>
        <div class="global__bottom-bar-mobile-height"></div>
      </div>
    </div>
  </div>

  <!-- Create Message Input -->
  <div class="global__top-bar__height messages-thread-view__message-builder">
    <div class="d-flex h-100">
      <textarea
        placeholder="Write message here..."
        (keypress)="_messageTextChanged($event)"
        [(ngModel)]="messageText"
        class="w-100 h-100 py-5px fs-15px messages-thread__border-radius flex-grow-1 form-control"
      ></textarea>
      <div class="d-flex align-items-end pb-16px h-100 background-color-form">
        <button
          *ngIf="!sendMessageBeingCalled"
          (click)="_sendMessage()"
          class="btn btn-primary fs-15px ml-15px messages-thread__border-radius mr-15px"
        >
        {{ 'messages.send' | transloco }}
        </button>
        <button *ngIf="sendMessageBeingCalled" class="btn btn-primary fs-15px ml-15px messages-thread__border-radius mr-15px">
          {{ 'messages.sending' | transloco }}...
        </button>
      </div>
    </div>
  </div>
</div>
