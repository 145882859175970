<div class="w-100 d-flex flex-column justify-content-start fs-15px">
  <div class="creator-diamonds__tab-container">
    <tab-selector
      [tabs]="['Given', 'Received']"
      [activeTab]="activeTab"
      [buttonSelector]="false"
      (tabClick)="_handleTabClick($event)"
    ></tab-selector>
  </div>
  <div>
    <div class="fs-15px flex-grow-1">
      <div
        class="row no-gutters border-bottom border-color-grey creator-diamonds__header"
        *ngIf="!isLoading && diamondSummaryList.length > 0"
      >
        <div class="col-5 d-flex align-items-center py-15px mb-0">{{ 'creator_diamonds.username' | transloco }}</div>
        <div class="col-4 text-left d-flex align-items-center py-15px mb-0" [ngClass]="{ 'd-flex justify-content-end': globalVars.isMobile() }">Most Diamonds</div>
        <div class="col-3 text-center d-flex align-items-center py-15px mb-0" [ngClass]="{ 'd-flex justify-content-end': globalVars.isMobile() }">Total Diamonds</div>
      </div>
      <div *ngIf="!isLoading && diamondSummaryList.length == 0" class="row no-gutters pt-10px creator-diamonds__no-diamonds">
        <div class="d-flex justify-content-center w-100">
          <div class="circle-div">
            <i-feather name="gem" class="feather-xlarge"></i-feather>
          </div>
        </div>
        <div class="d-flex justify-content-center w-100">
          <span>{{ profile.Username }} {{ 'creator_diamonds.no_diamonds_0' | transloco }} {{ activeTab.toLowerCase() }} {{ 'creator_diamonds.no_diamonds_1' | transloco }}</span>
        </div>
      </div>
      <simple-center-loader *ngIf="isLoading"></simple-center-loader>
      <div *ngIf="!isLoading || loadingNewSelection">
        <div #uiScroll *uiScroll="let row of datasource">
          <div *ngIf="!row.totalRow && row.ProfileEntryResponse">
            <div
              class="link--unstyled cursor-pointer diamond-row"
              [routerLink]="[
              '/' + globalVars.RouteNames.USER_PREFIX,
              showDiamondsGiven ? row.ProfileEntryResponse.Username : profile.Username,
              globalVars.RouteNames.DIAMONDS,
              showDiamondsGiven ? profile.Username : row.ProfileEntryResponse.Username,
            ]"
            >
              <div class="row no-gutters py-10px border-bottom mb-0">
                <div class="col-5 d-flex align-items-left mb-0">
                  <div
                    [routerLink]="['/' + globalVars.RouteNames.USER_PREFIX, row.ProfileEntryResponse.Username]"
                    class="d-flex align-items-center link--unstyled"
                  >
                    <div
                      [ngClass]="{
                        nft__avatar: row?.ProfileEntryResponse?.ExtraData?.NFTProfilePictureUrl
                      }"
                      class="creator-profile-details__hodler-avatar mr-10px"
                      [avatar]="row.ProfileEntryResponse.PublicKeyBase58Check"
                      [nftProfileUrl]="row.ProfileEntryResponse?.ExtraData?.NFTProfilePictureUrl"
                    ></div>
                    <div
                      class="text-truncate"
                      style="font-size: 14px"
                      [ngStyle]="{ 'max-width': globalVars.isMobile() ? '100px' : '200px' }"
                    >
                      <span>{{ row.ProfileEntryResponse.Username }}</span>
                      <span
                        *ngIf="row.ProfileEntryResponse.IsVerified"
                        (click)="tooltip.toggle()"
                        class="ml-1 mb-1 cursor-pointer text-primary"
                        matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
                        [matTooltip]="'This account is verified'"
                        #tooltip="matTooltip"
                      >
                        <i class="fas fa-check-circle fa-md align-middle"></i>
                      </span>
                      <div class="text-grey9 fs-12px">
                        {{ globalVars.nanosToUSD(row.ProfileEntryResponse.CoinPriceDeSoNanos, 2) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 mb-0 d-flex align-items-center justify-flex-start" [ngClass]="{ 'd-flex justify-content-end': globalVars.isMobile() }">
                  <div *ngIf="!globalVars.isMobile()">
                    <i-feather
                      *ngFor="let diamond of counter(row.HighestDiamondLevel)"
                      name="diamond" class="feather-medium fc-default"
                    ></i-feather>
                    <i-feather
                      *ngFor="let diamond of counter(maxGivableDiamonds - row.HighestDiamondLevel)"
                      name="diamond" class="feather-medium fc-muted"
                    ></i-feather>
                  </div>
                  <div *ngIf="globalVars.isMobile()">
                    <i-feather
                      name="diamond" class="feather-medium fc-default"
                    ></i-feather>
                    <i-feather class="fc-muted feather-xsmall" name="x"></i-feather>
                    <span class="fs-15px">{{row.HighestDiamondLevel}}</span>
                  </div>
                </div>
                <div
                  class="col-3 mb-0 d-flex align-items-center"
                  [ngClass]="{
                    'justify-content-end': globalVars.isMobile(),
                    'justify-content-center': !globalVars.isMobile()
                  }"
                  [ngStyle]="{ 'padding-right': globalVars.isMobile() ? '16px' : 'auto' }"
                >
                  {{ row.TotalDiamonds }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="row.anonDiamondsRow">
            <div
              class="link--unstyled"
            >
              <div class="row no-gutters py-10px border-bottom mb-0">
                <div class="col-5 d-flex align-items-left mb-0">
                  <div
                    class="d-flex align-items-center link--unstyled"
                  >
                    <div
                      class="creator-profile-details__hodler-avatar mr-10px"
                      style="background-image: url('/assets/img/default_profile_pic.png')"
                    ></div>
                    <div
                      class="text-truncate"
                      style="font-size: 14px"
                      [ngStyle]="{ 'max-width': globalVars.isMobile() ? '100px' : '200px' }"
                    >
                      <span>{{ 'creator_diamonds.anonymous' | transloco }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-4 mb-0 d-flex align-items-center justify-flex-start">
                  <i
                    *ngFor="let diamond of counter(highestAnonDiamondLevel)"
                    class="icon-diamond fs-20px d-block"
                    style="margin-right: -7px"
                  ></i>
                  <i-feather name="diamond" class="feather-medium fc-default"></i-feather>
                </div>
                <div class="col-3 mb-0 d-flex align-items-center justify-content-center">
                  {{ totalAnonDiamonds }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="row.totalRow" class="row no-gutters font-weight-bold diamond-total-row">
            <div class="col-4 py-15px mb-0">Total</div>
            <div class="col-4 py-15px mb-0"><!--Blank--></div>
            <div class="col-4 text-center py-15px mb-0" [ngClass]="{ 'd-flex justify-content-end': globalVars.isMobile() }" [ngStyle]="{ 'padding-right': globalVars.isMobile() ? '16px': '0px' }">
              {{ totalDiamonds }} ≈ {{ globalVars.abbreviateNumber(valueOfAllDiamonds(), 2, true) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
