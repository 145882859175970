<div class="modal-header">
  <div *ngIf="showModal" class="modal-header-x" (click)="bsModalRef.hide()">
    <i-feather name="x"></i-feather>
  </div>
</div>
<div [ngClass]="{ disappear: !showModal, appear: modalReappear }" class="buy-deso__container p-16px">
  <div [ngClass]="{ 'text-center': globalVars.isMobile() }" class="fs-20px fc-muted pb-10px mb-10px">Easily transfer $DESO or purchase some directly</div>
  <div class="border-bottom w-100 mb-30px"></div>
  <div class="d-flex mb-30px" [ngClass]="{ 'flex-column': globalVars.isMobile() }">
    <div class="d-flex align-items-center flex-column fc-blue">
      <div
        style="border-color: var(--highlight)"
        class="d-flex align-items-center justify-content-center fs-20px onboarding-info-bar-circle mb-10px"
      >
        A
      </div>
    </div>
    <div [ngClass]="{ 'ml-20px': !globalVars.isMobile() }" class="w-100">
      <div [ngClass]="{ 'text-center': globalVars.isMobile() }" class="fs-20px fc-default font-weight-bold mb-16px">Don't have any $DESO? We make it easy to buy.</div>
      <div class="p-30px mb-20px bg-light br-10px d-flex flex-column align-items-center">
        <div class="fc-default font-weight-bold fs-18px mb-20px text-center">Buy DESO with ETH, BTC, fiat, or on Coinbase</div>
        <div class="d-flex justify-content-around">
          <div class="col-10 d-flex align-items-center flex-column">
            <button (click)="openBuyDeSoModal(true)" class="primary-button mb-16px" style="width: 200px">Buy Now!</button>
            <div class="fs-12px fc-muted text-center">We support 20 fiat currencies including USD and more.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="border-bottom w-100 mb-30px"></div>
  <div class="d-flex" [ngClass]="{ 'flex-column': globalVars.isMobile() }">
    <div class="d-flex align-items-center flex-column fc-blue">
      <div
        style="border-color: var(--highlight)"
        class="d-flex align-items-center justify-content-center fs-20px onboarding-info-bar-circle mb-10px"
      >
        B
      </div>
    </div>
    <div [ngClass]="{ 'ml-20px': !globalVars.isMobile() }">
      <div [ngClass]="{ 'text-center': globalVars.isMobile() }" class="fs-20px fc-default font-weight-bold mb-16px">Transfer $DESO to your new account</div>
      <div [ngClass]="{ 'text-center': globalVars.isMobile() }" class="fs-16px fc-muted mb-30px">You will need at least 0.01 $DESO to create your new account. This could be transferred from an exchange like Coinbase, or from another account on a DeSo app like Diamond.</div>
      <div class="fs-18px fc-default font-weight-semibold mb-10px">Send $DESO to this address</div>
      <div
        class="w-100 bg-light p-10px br-10px cursor-pointer mb-10px"
        [ngClass]="{ 'flex-column': globalVars.isMobile() }"
      >
        <div
          [ngClass]="{ 'creator-profile__ellipsis-restriction-full': globalVars.isMobile() }"
          class="fs-16px font-weight-normal fc-default mb-10px mt-5px text-center"
        >
          {{ globalVars.loggedInUser.PublicKeyBase58Check }}
        </div>
        <div class="w-100 border-bottom"></div>
        <div class="d-flex justify-content-around mt-10px mb-5px" (click)="_copyPublicKey()">
          <span>
            <i-feather
              *ngIf="publicKeyIsCopied"
              class="feather-small check-indicator position-relative"
              name="check-circle"
            ></i-feather>
            <i-feather
              *ngIf="!publicKeyIsCopied"
              class="feather-small copy-button cursor-pointer position-relative"
              name="copy"
            ></i-feather>
            <span class="fs-16px fc-blue ml-5px">Click to Copy</span>
          </span>
          <span *ngIf="!scanQRCode" (click)="scanQRCode = true">
            <i class="fas fa-qrcode"></i>
            <span class="fs-16px fc-blue ml-5px">Send via QR Code</span>
          </span>
          <span class="d-flex align-items-center" *ngIf="scanQRCode" (click)="scanQRCode = false">
            <i-feather
              class="feather-small check-indicator position-relative fs-0px"
              name="x-square"
            ></i-feather>
            <span class="fs-16px fc-blue ml-5px">Close QR Code</span>
          </span>
        </div>
        <div *ngIf="scanQRCode" class="p-15px d-flex flex-column align-items-center">
          <p class="mb-10px fc-default fs-16px font-weight-semibold">Scan QR code to send {{ globalVars.loggedInUser.ProfileEntryResponse?.Username }} $DESO</p>
          <img style="border-radius: 15px" [src]="sendDeSoQRCode | sanitizeQRCode"/>
        </div>
      </div>
      <div class="d-flex mb-25px">
        <div class="fc-default fs-16px mr-5px font-weight-semibold">Your current balance</div>
        <div class="fs-16px fc-muted d-flex align-items-center">
          <div>{{ globalVars.nanosToDeSo(globalVars.loggedInUser.BalanceNanos) }} $DESO</div>
          <div (click)="refreshBalance()" class="ml-10px d-flex align-items-center cursor-pointer">
            <div class="d-flex"><i-feather class="fc-default feather-small fs-0px mr-5px" name="refresh-cw"></i-feather></div>
            <span class="fc-blue">Refresh</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
