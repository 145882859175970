<div>
  <div class="flex-grow-1 br-10px border background-color-white p-16px">
    <div class="d-flex w-100 justify-content-center">
      <div class="col-6 fc-muted d-flex align-items-center flex-column p-16px mb-16px">
        <div
          style="border-color: var(--border)"
          class="d-flex align-items-center justify-content-center fs-25px onboarding-info-bar-circle mb-10px"
        >
          1
        </div>
        <div class="text-center">How to buy DESO</div>
      </div>
      <div class="col-6 fc-blue d-flex align-items-center flex-column p-16px">
        <div
          style="border-color: var(--highlight)"
          class="d-flex align-items-center justify-content-center fs-25px onboarding-info-bar-circle mb-10px"
        >
          2
        </div>
        <div class="text-center">How to invest in creators</div>
      </div>
    </div>
    <div class="w-100 d-flex flex-column align-items-center">
      <div [ngClass]="{ 'w-90': globalVars.isMobile(), 'w-70': !globalVars.isMobile() }">
        <div class="fc-default fs-24px font-weight-bold text-align-center w-100 mb-30px">A creator coin is your way to show your appreciation</div>
        <div class="fc-default fs-16px text-center mb-16px">Creator coins allow you to participate in a creator's upside as they grow their community.<br/><br/>
          Find someone who is providing a lot of value? Reward them by buying a share of their coin!<br/><br/>
        </div>
      </div>
    </div>
    <div
      *ngIf="globalVars.loggedInUser?.ProfileEntryResponse?.Username"
      class="fs-16px text-center mb-25px font-weight-bold"
    >
      {{
        userCanAffordCCBuy
          ? "Try it out by purchasing your own Creator coin!"
          : "You can see a sample creator coin purchase below"
      }}
    </div>
    <div *ngIf="globalVars.loggedInUser?.ProfileEntryResponse?.Username" class="br-10px border mb-16px">
      <trade-creator
        [inTutorial]="true"
        [tutorialBuy]="true"
        [username]="globalVars.loggedInUser?.ProfileEntryResponse?.Username"
        [tradeType]="'Buy'"
      ></trade-creator>
    </div>
    <div class="w-100 d-flex justify-content-center">
      <button *ngIf="userCanAffordCCBuy && globalVars.loggedInUser?.ProfileEntryResponse?.Username" (click)="skipTutorialStep()" style="width: 215px" class="plain-button">Skip and complete tutorial</button>
      <button
        *ngIf="!userCanAffordCCBuy || !globalVars.loggedInUser?.ProfileEntryResponse?.Username"
        (click)="skipTutorialStep()"
        style="width: 215px"
        class="primary-button"
      >
        Complete tutorial
      </button>
    </div>
  </div>
  <!-- Bottom spacer for PWA apps-->
  <div *ngIf="globalVars.windowIsPWA() && globalVars.isMobile()" class="mt-20px"></div>
  <div class="global__bottom-bar-mobile-height"></div>
</div>
