<script src="../../environments/environment.prod.ts"></script>
<div class="position-relative landing-page-container-bg" *transloco="let t">
  <div class="position-relative landing-page-container-1">
    <div class="position-relative d-flex justify-content-between mt-30px landing-page-top-bar">
      <div class="fc-landing-page d-flex align-items-center">
        <img src="assets/diamond/camelcase_logo_new.svg" style="filter: invert(100)" height="28.63px"/>
      </div>
      <div class="fc-landing-page d-flex align-items-center">
        <a class="fc-landing-page mr-15px" (click)="globalVars.launchLoginFlow()">
          <i-feather *ngIf="!globalVars.isMobile()" name="lock-2" class="feather-small fc-landing-page mr-5px position-relative" style="top: -2px"></i-feather>
          {{t('landing.login')}}
        </a>
        <a *ngIf="globalVars.isMobile()" class="fc-landing-page" (click)="globalVars.launchSignupFlow()">
          {{t('landing.signup')}}
        </a>
        <button *ngIf="!globalVars.isMobile()" style="font-size: 16px; padding: 5px; width: 152px; height: 50px" class="landing-page-primary-btn-inverted landing-page-btn fs-16px" (click)="globalVars.launchSignupFlow()">{{t("landing.signuptoday")}}</button>
      </div>
    </div>
  <!--  TODO: Put all of this styling into sensible classes.-->
    <!--Title text-->
    <div class="landing-page-content-container d-flex flex-column justify-content-center">
      <div class="d-flex align-items-center justify-content-between w-100 h-100" [ngClass]="{ 'flex-column': globalVars.isMobile() }">
        <div data-aos="fade-right" data-aos-duration="500" data-aos-offset="-1000" style="top: 300px; z-index: 1" [ngStyle]="{ width: !globalVars.isMobile() ? '596px' : 'auto' }">
          <h1 class="landing-page-header-main">{{t('landing.settle')}}</h1>
          <h3 class="landing-page-subtext" style="margin-top: 18px">
            {{t("landing.diamonds")}}
            <br>
            <span class="d-block mt-15px">Built on DeSo — the Decentralized Social Blockchain.</span>
          </h3>
          <div
            style="margin-top: 43px"
            [ngClass]="{ 'mr-30px': !globalVars.isMobile(), 'flex-column': globalVars.isMobile() }"
          >
            <button
              class="landing-page-btn landing-page-primary-btn font-weight-bold"
              (click)="globalVars.launchSignupFlow()"
              [ngClass]="{ 'w-100': globalVars.isMobile() }"
            >
              <div class="mb-10px">Create Account</div>
<!--              <div class="landing-page-button-subtext">Get <free-deso-message></free-deso-message> free</div>-->
            </button>
          </div>
        </div>
        <div class="position-relative h-100 w-100" style="width: 600px !important">
          <div class="landing-page-nft-card-holder landing-page-robot-2">
            <img class="position-relative" src="/assets/img/example-nft-2.png" width="389.55px" height="447.11px" style="border-radius: 26.14px"/>
            <div class="position-relative d-flex justify-content-between fc-landing-page" style="top: 35px; margin-bottom: 36px">
              <div class="d-flex flex-column">
                <div class="landing-page-nft-card-fs-large landing-page-nft-card-fs-title">CloutPunk NFT #001</div>
                <div class="landing-page-nft-card-fs-small" style="margin-top: 10px">For Sale</div>
              </div>
              <div class="d-flex flex-column align-items-end">
                <div class="landing-page-nft-card-fs-small" style="margin-top: 2px">Min Bid</div>
                <div class="d-flex justify-content-end landing-page-nft-card-fs-large" style="margin-top: 5px">
                  $69,420
                </div>
              </div>
            </div>
          </div>
          <div class="landing-page-nft-card-holder landing-page-robot-1">
            <img class="position-relative" src="/assets/img/example-nft-1.png" width="389.55px" height="447.11px" style="border-radius: 26.14px"/>
            <div class="position-relative d-flex justify-content-between fc-landing-page" style="top: 35px; margin-bottom: 36px">
              <div class="d-flex flex-column">
                <div class="landing-page-nft-card-fs-large">Desomon #147</div>
                <div class="landing-page-nft-card-fs-small" style="margin-top: 10px">For Sale</div>
              </div>
              <div class="d-flex flex-column align-items-end">
                <div class="landing-page-nft-card-fs-small" style="margin-top: 2px">Min Bid</div>
                <div class="d-flex justify-content-end landing-page-nft-card-fs-large" style="margin-top: 5px">
                  <i-feather name="deso" class="feather-medium fc-none fs-0px mt-5px mr-16px"></i-feather>
                  0.9
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!--Background blobs-->
    </div>
  </div>



  <div class="m-auto text-center fc-landing-page landing-page-testimonial">
    <p class="fc-landing-page fs-18px mb-20px">Over $30 million dollars paid directly to creators</p>
    <p class="fs-25px mb-20px landing-page-testimonial-quote">&ldquo;we’ve made more on Diamond than anything we’ve ever tried&rdquo;</p>
    <div class="d-flex m-auto landing-page-testimonial-person align-items-center justify-center">
      <a href="https://diamondapp.com/u/goldberry" class="fc-landing-page d-block w-20" target="_blank"><img class="w-100 br-6px mr-15px" src="assets/diamond/goldberry.png"></a>
      <span class="fc-landing-page text-left pl-20px">Goldberry • <a class="fs-14px fc-landing-page text-underline" target="_blank" href="https://diamondapp.com/u/goldberry">View Profile</a><br>
        <span style="color:#666" class="fs-14px">Indie Folk Band, St. Louis</span>
      </span>
    </div>
  </div>

  <div class="landing-page-content-container mb-40px pb-20px">
    <div class="fs-32px font-weight-bold landing-page-creator-coins-explainer-section-title text-align-center mb-40px pb-40px">Finally, a monetizable social network, built for you.</div>

    <div class="d-flex justify-content-between" [ngClass]="{ 'flex-column': globalVars.isMobile() }">
      <div class="position-relative landing-page-creator-coin-explainer-container" [ngClass]="{ 'mr-25px': !globalVars.isMobile() }">
        <div class="d-flex justify-content-center landing-page-creator-coins-explainer-header">
          <img class="position-relative landing-page-explainer-image" src="/assets/diamond/icon-2.png"/>
        </div>
        <div class="d-flex flex-column align-items-center landing-page-creator-coins-explainer-body">
          <div class="fs-24px font-weight-bold landing-page-creator-coins-explainer-title text-align-center">Full ownership of your content &amp; identity</div>
        </div>
      </div>
      <div class="position-relative landing-page-creator-coin-explainer-container" [ngClass]="{ 'mr-25px': !globalVars.isMobile() }">
        <div class="d-flex justify-content-center landing-page-creator-coins-explainer-header">
          <img class="position-relative landing-page-explainer-image"src="/assets/diamond/icon-1.png"/>
        </div>
        <div class="d-flex flex-column align-items-center landing-page-creator-coins-explainer-body">
          <div class="fs-24px font-weight-bold landing-page-creator-coins-explainer-title text-align-center">Monetize value, for your holders — not advertisers</div>
        </div>
      </div>
      <div class="position-relative landing-page-creator-coin-explainer-container">
        <div class="d-flex justify-content-center landing-page-creator-coins-explainer-header">
          <img class="position-relative landing-page-explainer-image" src="/assets/diamond/icon-3.png"/>
        </div>
        <div class="d-flex flex-column align-items-center landing-page-creator-coins-explainer-body">
          <div class="fs-24px font-weight-bold landing-page-creator-coins-explainer-title text-align-center">Invest & grow with your favorite creators & fans</div>
        </div>
      </div>
    </div>
  </div>


  <div class="landing-page-content-container mb-40px text-center m-auto">
    <h1 class="landing-section-header mb-30px">LIKES ARE FOR WEB2</h1>
    <h1 class="landing-section-header mb-30px">DIAMONDS ARE FOREVER</h1>
  </div>


  <div class="landing-page-content-container mb-40px pb-20px">
    <div class="fs-32px font-weight-bold landing-page-creator-coins-explainer-title text-align-center mb-40px pb-40px">
        What makes Diamond unique &amp; unlike any other?
        <p class="fc-white fs-20px landing-page-creator-coins-explainer-desc">For starters, imagine if your next post could earn you up to $1,000</p>
    </div>
    <div class="d-flex justify-content-between" [ngClass]="{ 'flex-column': globalVars.isMobile() }">
      <div class="position-relative landing-page-creator-coin-explainer-container" [ngClass]="{ 'mr-25px': !globalVars.isMobile() }">
        <div class="d-flex justify-content-center landing-page-creator-coins-explainer-header">
          <img class="position-relative landing-page-explainer-image-small" src="/assets/diamond/icon-4.png"/>
        </div>
        <div class="d-flex flex-column align-items-center landing-page-creator-coins-explainer-body">
          <div class="fs-24px font-weight-bold landing-page-creator-coins-explainer-title text-align-center">Mint NFTs in a few clicks</div>
          <div class="fs-20px mt-16px text-align-center" style="color:#888">Any post you create on Diamond can be minted as an NFT. They're also virtually gas-free.</div>
        </div>
      </div>
      <div class="position-relative landing-page-creator-coin-explainer-container" [ngClass]="{ 'mr-25px': !globalVars.isMobile() }">
        <div class="d-flex justify-content-center landing-page-creator-coins-explainer-header">
          <img class="position-relative landing-page-explainer-image-small"src="/assets/diamond/icon-5.png"/>
        </div>
        <div class="d-flex flex-column align-items-center landing-page-creator-coins-explainer-body">
          <div class="fs-24px font-weight-bold landing-page-creator-coins-explainer-title text-align-center">Send tips with diamonds</div>
          <div class="fs-20px mt-16px text-align-center" style="color:#888">Instead of "Likes", you can send users real money with tips ("diamonds") directly.</div>
        </div>
      </div>
      <div class="position-relative landing-page-creator-coin-explainer-container">
        <div class="d-flex justify-content-center landing-page-creator-coins-explainer-header">
          <img class="position-relative landing-page-explainer-image-small" src="/assets/diamond/icon-6.png"/>
        </div>
        <div class="d-flex flex-column align-items-center landing-page-creator-coins-explainer-body">
          <div class="fs-24px font-weight-bold landing-page-creator-coins-explainer-title text-align-center">Buy creator coins</div>
          <div class="fs-20px mt-16px text-align-center" style="color:#888">Every profile has creator coins (social tokens) that you can purchase and invest in.</div>
        </div>
      </div>
    </div>
  </div>

  <div class="landing-page-content-container mb-40px pb-20px">
    <div class="d-flex justify-content-between" [ngClass]="{ 'flex-column': globalVars.isMobile() }">
      <div class="position-relative landing-page-creator-coin-explainer-container" [ngClass]="{ 'mr-25px': !globalVars.isMobile() }">
        <div class="d-flex justify-content-center landing-page-creator-coins-explainer-header">
          <img class="position-relative landing-page-explainer-image-small" src="/assets/diamond/icon-7.png"/>
        </div>
        <div class="d-flex flex-column align-items-center landing-page-creator-coins-explainer-body">
          <div class="fs-24px font-weight-bold landing-page-creator-coins-explainer-title text-align-center">Take your profile with you</div>
          <div class="fs-20px mt-16px text-align-center" style="color:#888">Thanks to the DeSo blockchain, your profile and social graph can be used across any DeSo app.</div>
        </div>
      </div>
      <div class="position-relative landing-page-creator-coin-explainer-container" [ngClass]="{ 'mr-25px': !globalVars.isMobile() }">
        <div class="d-flex justify-content-center landing-page-creator-coins-explainer-header">
          <img class="position-relative landing-page-explainer-image-small"src="/assets/diamond/icon-8.png"/>
        </div>
        <div class="d-flex flex-column align-items-center landing-page-creator-coins-explainer-body">
          <div class="fs-24px font-weight-bold landing-page-creator-coins-explainer-title text-align-center">Collect founders reward</div>
          <div class="fs-20px mt-16px text-align-center" style="color:#888">Set a founders reward percentage to collect revenue whenever someone buys your social token.</div>
        </div>
      </div>
      <div class="position-relative landing-page-creator-coin-explainer-container">
        <div class="d-flex justify-content-center landing-page-creator-coins-explainer-header">
          <img class="position-relative landing-page-explainer-image-small" src="/assets/diamond/icon-9.png"/>
        </div>
        <div class="d-flex flex-column align-items-center landing-page-creator-coins-explainer-body">
          <div class="fs-24px font-weight-bold landing-page-creator-coins-explainer-title text-align-center">Airdrop your NFTs</div>
          <div class="fs-20px mt-16px text-align-center" style="color:#888">Easily gift NFTs with an airdrop to your early adopters and most loyal supporters.</div>
        </div>
      </div>
    </div>
  </div>

  <div class="landing-page-content-container mb-40px">
    <div class="d-flex justify-content-center position-relative" style="z-index: 1" [ngClass]="{ 'flex-column': globalVars.isMobile() }">
      <button class="landing-page-btn landing-page-primary-btn font-weight-bold" (click)="globalVars.launchSignupFlow()">
        <div>Create Account</div>
<!--        <div class="landing-page-button-subtext pt-10px">Get <free-deso-message></free-deso-message> free</div>-->
      </button>
    </div>
  </div>
  <div class="position-relative d-flex justify-content-between landing-page-top-bar" style="padding: 50px 0px; margin-top: 0px; margin-bottom: 0px" [ngClass]="{ 'flex-column align-items-center': globalVars.isMobile() }">
    <div class="fc-landing-page d-flex align-items-center m-auto" [ngClass]=" {'mb-16px': globalVars.isMobile()}">
    </div>
  </div>
  <div class="text-align-center pb-30px">
    <p style="color: #888" class="fs-18px mb-40px d-block" [ngClass]="{ 'fs-12px': globalVars.isMobile() }">
      <img src="assets/diamond/camelcase_logo_new.svg" height="28px" style="filter: invert(100)" class="m-auto text-align-center"/> <span class="d-inline-block mr-20px ml-20px">&times;</span>
      <img src="assets/diamond/deso_logo_white.svg" height="28px" class="m-auto text-align-center"/>
    </p>
    <p style="color: #888" class="fs-18px mb-16px" [ngClass]="{ 'fs-12px': globalVars.isMobile() }">Diamond is built on DeSo — <a href="https://deso.org" target="_blank" class="fc-landing-page">the Decentralized Social Blockchain.</a></p>
    <p style="color: #666" class="w-40 m-auto mb-20px pb-20px">DeSo is the leading layer-1 blockchain built from the ground up to<br/>scale decentralized social applications to one billion users</p>
    <p class="mb-20px"> <a href="https://deso.org" target="_blank" class="text-underline fc-secondary"> Learn More About DeSo </a> </p>
    <p style="color: #444" class="fs-14px" [ngClass]="{ 'fs-12px': globalVars.isMobile() }">Copyright © 2022 Diamond Labs &bull; All Rights Reserved</p>
  </div>
</div>
