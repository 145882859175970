<div *ngIf="messageThreads" class="messages-inbox__threads vh-100 disable-scrollbars position-relative">
  <div *ngIf="_settingsTrayIsOpen()">
    <messages-filter-menu></messages-filter-menu>
  </div>
  <div class="messages-inbox__header d-flex align-items-center" *ngIf="!_settingsTrayIsOpen()">
    <!-- Tabs -->
    <tab-selector
      [tabs]="['All', 'Holders']"
      [activeTab]="activeTab"
      (tabClick)="_handleTabClick($event)"
    ></tab-selector>

    <i class="fas fa-ellipsis-h messages-inbox__custom-settings cursor-pointer" (click)="_toggleSettingsTray()"></i>
  </div>

  <!-- NOTE: We add any newMessageThread to the front of our messageThread array. -->
  <!-- This is used to handle the creation of new message threads. -->
  <div *ngFor="let thread of messageThreads; let ii = index">
    <messages-thread
      (click)="_handleMessagesThreadClick(thread)"
      [isSelected]="
        (selectedThread &&
          selectedThread.PublicKeyBase58Check &&
          thread.PublicKeyBase58Check == selectedThread.PublicKeyBase58Check) ||
        (selectedThread === undefined && ii === 0 && !isMobile)
      "
      [thread]="thread"
    ></messages-thread>
  </div>

  <div
    *ngIf="showMoreButton()"
    class="w-100 py-15px d-flex align-items-center justify-content-center cursor-pointer creator-leaderboard__load-more"
    (click)="loadMoreMessages()"
  >
    <div *ngIf="!fetchingMoreMessages" class="fs-15px">{{ 'messages.load_more' | transloco }}</div>
    <div *ngIf="fetchingMoreMessages" class="fs-15px">{{ 'messages.loading' | transloco }}...</div>
  </div>

  <!-- Spacer for PWA Apps -->
  <div *ngIf="globalVars.windowIsPWA() && globalVars.isMobile()" class="mt-20px"></div>
  <div class="global__bottom-bar-mobile-height"></div>
  <div class="global__bottom-bar-mobile-height"></div>
</div>
