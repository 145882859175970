<div>
  <div *ngIf="globalVars.isLeftBarMobileOpen" [@leftBarAnimation] class="left-bar-mobile__flyout-div">
    <left-bar [isMobile]="true" [inTutorial]="inTutorial" (closeMobile)="_closeMenu()"></left-bar>
  </div>
  <div
    *ngIf="globalVars.isLeftBarMobileOpen"
    [@translucentBackgroundAnimation]
    (click)="_closeMenu()"
    class="left-bar-mobile__translucent-background"
  ></div>
</div>
