<div class="w-100 light-grey-divider border-bottom border-color-grey" style="height:10px"></div>

<div class="px-15px pt-15px pb-5px fs-15px">
  <div class="py-5px w-100 d-flex justify-content-between align-items-center">
    <i (click)="previousDrop()" class="fas fa-chevron-left pl-5px pr-15px cursor-pointer"></i>
    <div class="d-flex align-items-center">
      <div class="font-weight-500">Drop #&nbsp;</div>
      <input [(ngModel)]="dropNumber"
             class="form-control fs-15px lh-18px p-10px"
             style="width: 75px; text-align: center" disabled/>
    </div>
    <div>
      <i (click)="nextDrop()" class="fas fa-chevron-right pr-5px pl-15px cursor-pointer"></i>
    </div>
  </div>
  <div *ngIf="dropSelectorError !== ''" class="fc-red">
    {{ dropSelectorError }}
  </div>
</div>

<div *ngIf="!loading">
  <div class="px-15px pb-15px fs-15px">
    <div *ngIf="!hideDateTimeAdjuster"
         [ngClass]="{'d-flex align-items-center': !globalVars.isMobile()}">
      <input type="text"
             [(ngModel)]="dropTime"
             placeholder="Datepicker"
             class="form-control fs-15px"
             bsDatepicker>
      <div class="d-flex align-items-center justify-content-between">
        <timepicker [(ngModel)]="dropTime"
                    class="fs-15px pr-15px"
                    [ngClass]="{'pl-15px': !globalVars.isMobile()}"></timepicker>
        <div>
          <a (click)="setDate()" class="btn btn-warning fs-15px ml-5px font-weight-500"
             style="white-space: nowrap">
            <span *ngIf="!settingDate">&nbsp;Set Date&nbsp;</span>
            <span *ngIf="settingDate">Setting...</span>
          </a>
        </div>
      </div>
    </div>
    <pre *ngIf="!hideDateTimeAdjuster" class="alert alert-info">
      <b>Drop time will be set to: </b>
      <br>
      {{dropTime}}
      <br><br>
      <span *ngIf="dropEntry && dropEntry.DropTstampNanos !== 0; else elseBlock">
        <b>Current Drop State</b>
        <br>
        Date: {{dropEntry.Date}}
        <br>
        Is Active: {{dropEntry.IsActive}}
        <span *ngIf="dropEntry.NFTHashes">
          <br>
          NFT Count: {{dropEntry.NFTHashes.length}}
        </span>
        <span *ngIf="!dropEntry.NFTHashes">
          <br>
          NFT Count: 0
        </span>
      </span>
      <ng-template #elseBlock>
        <b>This drop has not been created. Click "Set Date" to begin.</b>
      </ng-template>
    </pre>
    <pre *ngIf="hideDateTimeAdjuster" class="alert alert-info">This NFT drop occurred on: <br>{{dropTime}}</pre>
    <div *ngIf="dropEntry && dropEntry.DropTstampNanos != 0">
      <div *ngIf="isUpdatable" class="d-flex">
        <input [(ngModel)]="nftToAdd"
               class="form-control fs-15px lh-18px p-10px"
               placeholder="Enter post hash to add." />
        <div>
          <a (click)="addAnNFT()" class="btn btn-primary fs-15px ml-5px font-weight-500"
             style="white-space: nowrap">
            Add an NFT
          </a>
        </div>
      </div>
      <div *ngIf="!hideDateTimeAdjuster" class="d-flex pt-15px">
        <div>
          <a (click)="toggleActivation()" class="btn btn-primary fs-15px font-weight-500"
             style="white-space: nowrap">
            <span *ngIf="!dropEntry.IsActive">
              Activate Drop
            </span>
            <span *ngIf="dropEntry.IsActive">
              Deactivate Drop
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<simple-center-loader *ngIf="loading"></simple-center-loader>

<div class="w-100 light-grey-divider border-bottom border-top border-color-grey" style="height:10px"></div>

<div *ngIf="posts.length > 0 && (!loading || loadingNewDrop)" class="p-15px fs-15px">
  <b *ngIf="!loadingNewDrop">NFTs in this drop:</b>
  <div #uiScroll *uiScroll="let post of datasource" [ngClass]="{'d-flex align-items-center mt-15px border border-color-grey br-8px': !loadingNewDrop}">
    <i (click)="removeNFT(post.PostHashHex)"
       class="p-10px far fa-trash-alt fc-red cursor-pointer" *ngIf="!loadingNewDrop"></i>
    <feed-post
      class="flex-grow-1 border-left border-color-grey cursor-pointer"
      *ngIf="post.PostHashHex !== nftBeingRemoved && !loadingNewDrop"
      [post]="post"
      [includePaddingOnPost]="true"
      [showReplyingToContent]="!!post.parentPost"
      [parentPost]="post.parentPost"
      [contentShouldLinkToThread]="true"
    ></feed-post>
    <span *ngIf="post.PostHashHex === nftBeingRemoved">&nbsp;Removing...</span>
  </div>
</div>
