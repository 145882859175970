import { Component } from "@angular/core";
import { BackendApiService } from "src/app/backend-api.service";
import { RouteNames } from "../../app-routing.module";

@Component({
  selector: "get-starter-deso",
  templateUrl: "./get-starter-deso.component.html",
  styleUrls: ["./get-starter-deso.component.scss"],
})
export class GetStarterDeSoComponent {
  constructor() {}
}
