<div *ngIf="creatorCoinTrade.isCreatorCoinTransfer(); else elseBlock">
  <!-- Amount you're transferring -->
  <div *ngIf="!displayForCreatorForm" class="py-3 border-bottom d-flex justify-content-between flex-wrap align-items-center">
    <span>Recipient</span>

    <div class="d-inline-block" style="max-width: 70%; overflow-wrap: anywhere">
      <simple-profile-card
        [profile]="creatorCoinTrade.transferRecipient.value"
        [singleColumn]="true"
        [hideFollowLink]="true"
        *ngIf="creatorCoinTrade.transferRecipient.value"
      ></simple-profile-card>
    </div>
  </div>

  <!-- Amount you're transferring -->
  <div class="py-3 border-bottom d-flex justify-content-between flex-wrap">
    <span>You're transferring</span>

    <div class="d-inline-block">
      {{ creatorCoinTrade.amount.value | number: "0.0-9" }}
      {{ creatorCoinTrade.assetToSellString() }}
    </div>
  </div>

  <!-- Network fee -->
  <div class="py-3 border-bottom d-flex justify-content-between flex-wrap">
    <span>Network Fee</span>

    <div class="d-inline-block" style="text-align: end">
      {{ globalVars.nanosToDeSo(creatorCoinTrade.networkFeeNanos, 9) }}
      DeSo

      <span *ngIf="!creatorCoinTrade.isBuyingCreatorCoin" class="d-none d-lg-inline">
        ≈ {{ globalVars.nanosToUSD(creatorCoinTrade.networkFeeNanos, 9) }}
        USD
      </span>

      <div *ngIf="!creatorCoinTrade.isBuyingCreatorCoin" class="d-lg-none" style="text-align: end">
        ≈ {{ globalVars.nanosToUSD(creatorCoinTrade.networkFeeNanos, 9) }}
        USD
      </div>
    </div>
  </div>
</div>

<ng-template #elseBlock>
  <!-- Amount you're selling -->
  <div class="py-3 border-bottom d-flex justify-content-between flex-wrap">
    <span>You're exchanging</span>

    <div class="d-inline-block" id="tutorial-amount-purchasing">
      <span *ngIf="!inTutorial || !creatorCoinTrade.isBuyingCreatorCoin">
        {{ creatorCoinTrade.assetToSellAmount() | number: "0.0-9" }}
        {{ creatorCoinTrade.assetToSellString() }}
      </span>

      <span *ngIf="creatorCoinTrade.isBuyingCreatorCoin" [ngClass]="{ 'text-muted': !inTutorial }">
        {{ !inTutorial ? "≈" : "" }} {{ creatorCoinTrade.assetToSellAmountInUsd() | currency }}
        USD
      </span>
    </div>
  </div>

  <!-- Amount you're receiving -->
  <div class="py-3 border-bottom d-flex justify-content-between flex-wrap">
    <span>You receive</span>

    <div class="d-inline-block" id="tutorial-amount-selling">
      <span *ngIf="!inTutorial || creatorCoinTrade.isBuyingCreatorCoin">
        {{ creatorCoinTrade.assetReturnedAmount() | number: "0.0-9" }}
        {{ creatorCoinTrade.assetReturnedString() }}
      </span>

      <span *ngIf="!creatorCoinTrade.isBuyingCreatorCoin" [ngClass]="{ 'text-muted': !inTutorial }">
        {{ !inTutorial ? "≈" : "" }} {{ creatorCoinTrade.assetReturnedAmountInUsd() | currency }}
        USD
      </span>
    </div>
  </div>

  <!-- Price -->
  <div
    *ngIf="creatorCoinTrade.desoPriceOfCreatorCoin()"
    class="py-3 border-bottom d-flex justify-content-between flex-wrap"
  >
    <span>Price per coin</span>

    <div class="d-inline-block">
      <span *ngIf="!inTutorial">
        ~{{ creatorCoinTrade.desoPriceOfCreatorCoin().toFixed(4) }}
        DeSo
      </span>

      <span [ngClass]="{ 'text-muted': !inTutorial }">
        {{ !inTutorial ? "≈" : "" }} {{ creatorCoinTrade.usdPriceOfCreatorCoin() | currency }}
        USD
      </span>
    </div>
  </div>

  <!-- Founder's Reward Warning -->
  <div
    *ngIf="creatorCoinTrade.getFoundersRewardPercent() > 0 && !creatorCoinTrade.isBuyingOwnCoin() && !inTutorial"
    class="py-3 text-muted d-flex ml-5px"
  >
    <div class="mr-10px">
      <i class="fas fa-heart fa-md text-grey5"></i>
    </div>
    <div [ngClass]="{ 'text-danger': creatorCoinTrade.showFounderRewardWarning() }" class="text-grey5">
      @{{ creatorCoinTrade.creatorProfile.Username }} will receive
      <span class="font-weight-semibold">{{ creatorCoinTrade.getFoundersRewardPercent() | number: "0.0-9" }}%</span>
      of your purchase as a
      <span style="white-space: nowrap">
        founder reward
        <i
          (click)="tooltip.toggle()"
          class="fas fa-info-circle text-grey5 fs-15px global__tooltip-icon"
          matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
          [matTooltip]="TradeCreatorTableComponent.FOUNDER_REWARD_EXPLANATION"
          #tooltip="matTooltip"
        ></i>
      </span>
    </div>
  </div>
  <div
    *ngIf="
      !inTutorial &&
      !(
        followService._isLoggedInUserFollowing(creatorCoinTrade.creatorProfile.PublicKeyBase58Check) ||
        appData.loggedInUser.PublicKeyBase58Check === creatorCoinTrade.creatorProfile.PublicKeyBase58Check ||
        creatorCoinTrade.tradeType !== buyVerb
      )
    "
    class="py-1 border-bottom text-muted d-flex text-grey9 follow-on-buy"
  >
    <button
      class="checkbox-circle"
      [ngClass]="{ checked: creatorCoinTrade.followCreator }"
      (click)="creatorCoinTrade.followCreator = !creatorCoinTrade.followCreator"
    >
      <i-feather name="check"></i-feather>
    </button>
    <span class="ml-5px">Follow {{ creatorCoinTrade.creatorProfile.Username }}</span>
  </div>
</ng-template>
