<!--
  DESKTOP
  -->
<div class="global__desktop" *ngIf="!globalVars.isMobile()">
  <div class="desktop-top-bar">
    <div class="d-flex justify-content-between align-items-center w-100 h-100 desktop-top-bar-width">
      <a
        class="link--unstyled d-flex align-items-center ml-15px"
        (click)="globalVars.isLeftBarMobileOpen = false"
        [routerLink]="homeLink()"
        queryParamsHandling="merge"
      >
        <img
          [src]="environment.node.logoAssetDir + 'camelcase_logo_new.svg'"
          class="deso"
          style="height: 30px"
        />
      </a>
      <div class="d-flex align-items-center">
        <div class="position-relative mr-15px" style="width: 343px; height: 48px">
          <search-bar [resultsUnderBar]="true"></search-bar>
        </div>
        <change-account-selector></change-account-selector>
      </div>
    </div>
  </div>
  <div class="d-flex" style="margin-top: 64px">
    <left-bar></left-bar>

    <div class="global__content__flex">
      <div class="global__content__width messages-page__container">
        <div class="messages-page__header d-flex justify-content-between align-items-center">
          <div>
            <span>Messages</span>
          </div>
          <div>
            <button (click)="openNewMessageModal()">{{ 'messages.new_message' | transloco }}</button>
          </div>
        </div>
        <div class="d-flex messages-page__height-div">
          <messages-inbox
            class="messages-inbox__desktop-column"
            (selectedThreadEmitter)="selectedThread = $event"
            [messageThreads]="globalVars.messageResponse ? globalVars.messageResponse.OrderedContactsWithMessages : null"
          ></messages-inbox>
          <messages-thread-view
            class="messages-thread__desktop-column"
            [messageThread]="
              globalVars.messageResponse
                ? selectedThread
                  ? selectedThread
                  : globalVars.messageResponse.OrderedContactsWithMessages[0]
                : null
            "
          ></messages-thread-view>

        </div>
      </div>
    </div>
  </div>
</div>

<!--
  MOBILE
  -->
<div class="global__mobile flex-column" *ngIf="globalVars.isMobile()">
  <left-bar-mobile></left-bar-mobile>

  <div class="d-flex flex-column flex-grow-1">
    <top-bar-mobile-header
      [simpleTopBar]="showThreadView"
      [title]="selectedThread?.ProfileEntryResponse?.Username"
      [backButtonFn]="backButtonFunction"
      [publicKeyBase58Check]="selectedThread?.PublicKeyBase58Check"
      [profileEntryResponse]="selectedThread?.ProfileEntryResponse"
    ></top-bar-mobile-header>
    <!-- Thread View Header -->
    <div
      *ngIf="showThreadView"
      class="global__top-bar__height d-flex align-items-center fs-18px font-weight-bold pl-15px border-bottom border-color-grey"
    >
      <div class="cursor-pointer color-grey7 mr-15px">
        <a class="link--unstyled" style="text-align: center" (click)="navigateToInbox()" queryParamsHandling="merge">
          <i class="fa fa-angle-left"></i>
        </a>
      </div>

      <div
        class="messages-thread__avatar mr-15px"
        [avatar]="selectedThread.PublicKeyBase58Check"
        [ngClass]="{ 'cursor-auto': !selectedThread.ProfileEntryResponse }"
        [routerLink]="
          selectedThread.ProfileEntryResponse ? AppRoutingModule.profilePath(selectedThreadDisplayName) : []
        "
        queryParamsHandling="merge"
      ></div>
      <a
        class="link--unstyled"
        [routerLink]="
          selectedThread.ProfileEntryResponse ? AppRoutingModule.profilePath(selectedThreadDisplayName) : []
        "
        queryParamsHandling="merge"
      >
        {{ selectedThreadDisplayName }}
      </a>
      <span *ngIf="selectedThread.ProfileEntryResponse?.IsVerified" class="ml-1 text-primary">
        <i class="fas fa-check-circle fa-md align-middle"></i>
      </span>
    </div>

    <div class="d-flex" id="header-content-spacer" [ngStyle]="{ 'margin-top': !globalVars.isMobile() ? '64px' : showThreadView ? '0px' : '60px' }">
      <!-- Scrollable Content -->
      <div class="global__mobile-scrollable-section" style="overflow-y: hidden">
        <messages-inbox
          *ngIf="!showThreadView"
          class="global__mobile-scrollable-section"
          (selectedThreadEmitter)="_handleMessageThreadSelectedMobile($event)"
          [isMobile]="true"
          [messageThreads]="globalVars.messageResponse ? globalVars.messageResponse.OrderedContactsWithMessages : null"
        ></messages-inbox>
        <messages-thread-view
          *ngIf="showThreadView"
          class="messages-thread__desktop-column"
          [isMobile]="true"
          [messageThread]="
            globalVars.messageResponse
              ? selectedThread
                ? selectedThread
                : globalVars.messageResponse.OrderedContactsWithMessages[0]
              : null
          "
        ></messages-thread-view>
      </div>
    </div>
    <bottom-bar-mobile
      [ngClass]="{ 'pwa-bottom-bar-mobile': globalVars.windowIsPWA() && globalVars.isMobile() }"
      *ngIf="!showThreadView"
      class="global__bottom-bar-mobile"
    ></bottom-bar-mobile>
  </div>
  <a
    class="messages-mobile__new-message-circle d-flex justify-content-center align-items-center cursor-pointer"
    *ngIf="!showThreadView"
    (click)="openNewMessageModal()"
  >
    <i-feather name="plus"></i-feather>
  </a>
</div>
