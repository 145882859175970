<div class="w-100">
  <div>
    <div class="p-16px">
      <div class="mint-nft__sub-title" style="margin-top: 0px">
        {{ "buy_deso.exchange_amount" | transloco }}
      </div>
      <div class="d-flex">
        <div class="d-flex" dropdown (click)="$event.stopPropagation()" container="body" style="align-items: center">
          <button class="mint-nft__dropdown-btn d-flex align-content-between" role="button" dropdownToggle>
            <span>{{ selectedFiatCurrency }}</span>
            <i-feather name="chevron-down" class="feather-small"></i-feather>
          </button>
          <div
            class="dropdown-menu dropdown-menu-right p-0 bc-dropdown-menu place-bid-dropdown buy-deso__currency-dropdown"
            *dropdownMenu
          >
            <div>
              <a
                *ngFor="let fiat of supportedFiatCurrencies | keyvalue"
                class="dropdown-menu-item fs-12px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
                (click)="onSelectFiatCurrency(fiat.key)"
              >
                {{ fiat.key }}
              </a>
            </div>
          </div>
        </div>
        <div class="position-relative w-100">
          <input
            [(ngModel)]="amount"
            (input)="updateQuotation()"
            style="border-radius: 0px 6px 6px 0px"
            aria-describedby="usd-label"
            class="mint-nft__input"
            type="number"
            min="0"
            placeholder="0"
          />
        </div>
      </div>

      <div class="mint-nft__sub-title" style="margin-top: 30px">
        {{ "buy_deso.receive_amount" | transloco }}
      </div>

      <div class="d-flex">
        <div class="d-flex" container="body" style="align-items: center">
          <div class="mint-nft__dropdown-btn">$DESO</div>
        </div>
        <div class="position-relative w-100">
          <div class="mint-nft__input" style="border-radius: 0px 6px 6px 0px">
            {{ desoReceived }}
          </div>
        </div>
      </div>

      <div class="mint-nft__sub-title" style="margin-top: 30px">
        {{ "buy_deso.country" | transloco }}
      </div>

      <div class="d-flex" style="align-items: center">
        <div dropdown (click)="$event.stopPropagation()" container="body">
          <button class="d-flex justify-content-between buy-deso__country-selector" role="button" dropdownToggle>
            <span>{{ selectedCountry }}</span>
            <i-feather name="chevron-down" class="feather-small"></i-feather>
          </button>
          <div class="dropdown-menu dropdown-menu-right p-0 bc-dropdown-menu buy-deso__country-dropdown" *dropdownMenu>
            <div>
              <a
                *ngFor="let country of supportedCountries"
                class="dropdown-menu-item fs-12px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
                (click)="onSelectCountry(country)"
              >
                {{ country }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 border-bottom mt-15px"></div>
    <div class="fs-15px fc-red pb-15px" *ngIf="quotationError">
      {{ quotationError }}
    </div>
    <div class="m-16px">
      <button
        class="primary-button"
        (click)="onBuyClicked()"
        [ngClass]="{ 'disabled-button': quotationError }"
        [disabled]="quotationError"
      >
        {{ "buy_deso.buy_deso" | transloco }}
      </button>
      <a class="fc-muted ml-25px" (click)="cancel()">Cancel</a>
    </div>
  </div>
</div>
