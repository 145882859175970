<div class="background-color-white br-10px pb-16px">
  <!--Header-->
  <div
    class="d-flex align-items-center justify-content-between w-100 px-15px fs-18px font-weight-bold border-bottom border-color-grey back"
    style="min-height: 80px"
  >
    <div class="d-flex align-items-center">
      <div class="font-weight-bold fs-18px">
        {{ creatorCoinTrade.tradeVerbString() }} {{ creatorCoinTrade.creatorProfile.Username }} coin
      </div>
    </div>
  </div>

  <!--CTA-->
  <div class="text-center px-20px not-found__content-container" style="margin-top: 16px">
    <a (click)="globalVars.launchSignupFlow()" class="font-weight-bold">Create an account</a>
    or
    <a (click)="globalVars.launchLoginFlow()" class="font-weight-bold">log in</a>
    <br />
    to {{ creatorCoinTrade.tradeVerbString().toLowerCase() }} {{ creatorCoinTrade.creatorProfile.Username }} coin.
  </div>
</div>
