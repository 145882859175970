<div class="global__sidebar__inner" *transloco="let t">
  <!-- Sign Up Flow -->
  <div *ngIf="globalVars.isMobile() && globalVars.loggedInUser == null" class="mb-30px">
    <right-bar-signup></right-bar-signup>
  </div>
  <!-- DESO Price / Balance -->
  <div class="right-bar-creators__balance-box" *ngIf="!inTutorial">
    <div>
      <div class="d-flex">
        <div class="w-100 deso-metrics">
          <div class="d-flex justify-content-between">
            <div>{{ t("right_bar.creators.deso_price") }}</div>
            <div>
              <span>≈{{ globalVars.desoToUSDExchangeRateToDisplay }}</span>
              <span>&nbsp;USD</span>
            </div>
          </div>
          <div *ngIf="globalVars.loggedInUser" class="d-flex justify-content-between pt-10px">
            <div class="d-flex" style="min-width: 130px">{{ t("right_bar.creators.your_deso") }}</div>
            <div class="d-flex flex-column justify-content-end flex-wrap" style="align-items: flex-end">
              <div>
                <!-- Amount in BitClout-->
                {{ globalVars.nanosToDeSo(globalVars.loggedInUser.BalanceNanos) }}
                <span *ngIf="globalVars.satoshisPerDeSoExchangeRate">
                  ≈{{ globalVars.nanosToUSD(globalVars.loggedInUser.BalanceNanos, 2) }}
                </span>
              </div>
              <!-- Amount in USD-->
              <div *ngIf="!globalVars.satoshisPerDeSoExchangeRate">Fetching... USD</div>
            </div>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <a (click)="openBuyCloutModal()">
        <div class="d-flex justify-content-center cursor-pointer">
          <div class="buy-deso">
            <span>{{ t("right_bar.creators.buy_deso") }}</span>
          </div>
        </div>
      </a>
    </div>
  </div>

  <div
    *ngIf="globalVars.loggedInUser && !globalVars.loggedInUser.EmailVerified && !inTutorial"
    class="bg-light mb-20px fs-13px"
  >
    <div class="jumio-status">
      <div class="cursor-pointer">
        <div *ngIf="globalVars.loggedInUser.HasEmail">
          <a (click)="globalVars.resendVerifyEmail()" *ngIf="!globalVars.resentVerifyEmail">
            <i-feather name="send"></i-feather>
            <span>Resend Verification Email</span>
            <i-feather name="chevron-right" class="right-20px"></i-feather>
          </a>
          <div *ngIf="globalVars.resentVerifyEmail">
            <i-feather name="check"></i-feather>
            <span>{{ t("right_bar.creators.sent") }}</span>
          </div>
        </div>
        <a
          *ngIf="!globalVars.loggedInUser.HasEmail"
          [routerLink]="'/' + globalVars.RouteNames.UPDATE_PROFILE"
          class="font-weight-bold"
        >
          <i-feather name="mail"></i-feather>
          <span>{{ t("right_bar.creators.update_your_email") }}</span>
          <i-feather name="chevron-right" class="right-20px"></i-feather>
        </a>
      </div>
    </div>
  </div>

  <app-earnings-card *ngIf="this.earningsProfile" [profile]="this.earningsProfile"></app-earnings-card>

  <div class="trend-box" *ngIf="!inTutorial">
    <!-- Title -->
    <div class="trend-title">
      <div class="d-flex">
        <span>{{ t(activeTab) }}</span>
        <!-- Dropdown -->
        <div
          (click)="$event.stopPropagation()"
          class="btn-group cursor-pointer d-flex align-items-center trend-selector"
          dropdown
          #dropdown="bs-dropdown"
        >
          <div>
            <a
              class="link--unstyled"
              dropdownToggle
              id="trendsActionsButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-ellipsis-h"></i>
            </a>
          </div>
          <div class="dropdown-menu feed-post__dropdown-menu-item-repost" style="min-width: 6rem" *dropdownMenu>
            <div>
              <a
                *ngFor="let option of RightBarCreatorsComponent.chartMap | keyvalue"
                class="dropdown-menu-item d-block link--unstyled p-5px feed-post-icon-row__icon-repost-option-text"
                (click)="this.switchCreatorTab(option.key, $event)"
                data-toggle="dropdown"
              >
                {{ t(option.key) }}
              </a>
              <a
                class="dropdown-menu-item d-block link--unstyled p-5px feed-post-icon-row__icon-repost-option-text"
                data-toggle="dropdown"
                href=""
                [routerLink]="'/' + globalVars.RouteNames.TRENDS"
                queryParamsHandling="merge"
              >
                Trends Page
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex" *ngIf="activeRightTabOption.poweredBy">
        <a class="link--unstyled fs-12px text-grey7" [href]="activeRightTabOption.poweredBy.link" target="_blank">
          <i class="fas fa-external-link-alt pr-5px"></i>
          {{ t("right_bar.creators.powered_by") }} {{ activeRightTabOption.poweredBy.name }}
        </a>
      </div>
    </div>

    <!-- Creators -->
    <right-bar-creators-leaderboard [activeTab]="activeTab" *ngIf="!inTutorial"></right-bar-creators-leaderboard>
  </div>
</div>
