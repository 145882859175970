<div class="modal-header" *ngIf="!inTutorial">
  <div class="modal-header-x" (click)="bsModalRef.hide()">
    <i-feather name="x"></i-feather>
  </div>
</div>
<trade-creator
  [username]="username"
  [tradeType]="tradeType"
  [inTutorial]="inTutorial"
  [tutorialBuy]="tutorialBuy"
  (hideModal)="bsModalRef.hide()"
></trade-creator>
