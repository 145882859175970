import { Component } from "@angular/core";

@Component({
  selector: "creators-leaderboard-app-page",
  templateUrl: "./creators-leaderboard-app-page.component.html",
  styleUrls: ["./creators-leaderboard-app-page.component.scss"],
})
export class CreatorsLeaderboardAppPageComponent {
  constructor() {}
}
