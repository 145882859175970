<div class="modal-header">
  <div class="modal-header-x" (click)="closeModal()">
    <i-feather name="x"></i-feather>
  </div>
</div>
<nft-burn
  [burnNFTEntryResponses]="burnNFTEntryResponses"
  [post]="post"
  [postHashHex]="postHashHex"
  (closeModal)="closeModalEvent($event)"
></nft-burn>
