<app-page>
  <div class="buy-deso__container p-16px border border-color-grey">
    <div class="mint-nft__title">My Referrals</div>
    <div class="mint-nft__text mb-16px">
      The link{{ globalVars.loggedInUser.ReferralInfoResponses?.length > 1 ? "s" : "" }} below gives you and your
      referrals a bonus on sign up. Note that all bonuses are shown in USD but paid in the DESO equivalent at the time
      of sign up.
    </div>
    <div *ngIf="globalVars.loggedInUser">
      <div *ngFor="let referral of globalVars.loggedInUser?.ReferralInfoResponses; let ii = index">
        <div class="p-15px fs-15px br-10px border border-color-grey background-color-secondary">
          <div class="d-flex align-items-center">
            <a class="fs-15px" (click)="_copyLink(ii)">
              {{ globalVars.getLinkForReferralHash(referral.Info.ReferralHashBase58) }}
            </a>
            <i-feather
              *ngIf="!linkCopied[ii]"
              (click)="_copyLink(ii)"
              name="copy"
              class="feather-medium fc-secondary ml-10px position-relative cursor-pointer"
              style="top: -2px"
            ></i-feather>
            <div *ngIf="linkCopied[ii]" class="d-flex ml-10px">
              <i-feather
                name="check-circle"
                class="feather-medium fc-secondary position-relative"
                style="top: -2px"
              ></i-feather>
              <div class="mint-nft__sub-text ml-10px">Copied</div>
            </div>
          </div>
          <img
            [src]="globalVars.getLinkForReferralHash(referral.Info.ReferralHashBase58) | sanitizeQRCode"
            *ngIf="referral.IsActive"
            class="mt-15px"
          />
          <div *ngIf="!referral.IsActive" class="fc-red">This link is no longer active.</div>

          <div class="font-weight-bold mt-15px">
            <span>You get ${{ referral.Info.ReferrerAmountUSDCents / 100 }} USD per referral</span>
            <free-deso-message [hideMessage]="true"></free-deso-message>
          </div>
          <div>
            Total Collected:
            {{ globalVars.nanosToDeSo(referral.Info.TotalReferrerDeSoNanos) }} DESO
            ({{ globalVars.nanosToUSD(referral.Info.TotalReferrerDeSoNanos) }})
          </div>

          <div class="font-weight-bold mt-15px">
            <span>Your referrals get ${{ referral.Info.RefereeAmountUSDCents / 100 }} USD</span>
            <free-deso-message [hideMessage]="true"></free-deso-message>
          </div>
          <div>
            Total Given:
            {{ globalVars.nanosToDeSo(referral.Info.TotalRefereeDeSoNanos) }} DESO
            ({{ globalVars.nanosToUSD(referral.Info.TotalRefereeDeSoNanos) }})
          </div>

          <div class="mt-15px">
            <span class="font-weight-bold">Referrals remaining: </span>
            {{ referral.Info.MaxReferrals > 0 ?
            referral.Info.MaxReferrals - referral.Info.TotalReferrals
            : 'Unlimited'
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</app-page>
