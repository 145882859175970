<div class="btn-group" dropdown #dropdown="bs-dropdown" (mouseenter)="stopIconHide()" (mouseleave)="hideIcons()">
  <div class="wallet-actions-container d-flex align-content-between" *ngIf="showIcons && (!inTutorial || (isHighlightedCreator && showSellOnly))" [ngClass]="{ 'selected-wallet-actions-container': inTutorial && isHighlightedCreator }">
    <a
      class="dropdown-menu-item fs-15px d-block link--unstyled p-10px feed-post__dropdown-menu-item fc-blue"
      (click)="openBuyCreatorCoinModal($event, buyTradeType)"
      *ngIf="!showSellOnly"
    >
      <i class="fas fa-plus fs-12px"></i>
      {{ 'wallet.buy' | transloco }}
    </a>
    <a
      class="dropdown-menu-item fs-15px d-block link--unstyled p-10px feed-post__dropdown-menu-item fc-blue"
      (click)="openBuyCreatorCoinModal($event, sellTradeType)"
    >
      <i class="fas fa-minus fs-12px"></i>
      {{ 'wallet.sell' | transloco }}
    </a>
    <a
      class="dropdown-menu-item fs-15px d-block link--unstyled p-10px feed-post__dropdown-menu-item fc-blue"
      (click)="openBuyCreatorCoinModal($event, transferTradeType)"
      *ngIf="!showSellOnly"
    >
      <i class="fas fa-paper-plane fs-12px"></i>
      {{ 'wallet.send' | transloco }}
    </a>
    <a
      class="dropdown-menu-item fs-15px d-block link--unstyled p-10px feed-post__dropdown-menu-item fc-blue"
      (click)="showIcons = false"
      *ngIf="!showSellOnly && globalVars.isMobile()"
    >
      <i-feather name="x" class="feather-small fc-secondary"></i-feather>
    </a>
  </div>
  <a (click)="showIcons = true" *ngIf="!showIcons">
    <i class="fas fa-ellipsis-h fs-18px fc-secondary wallet-actions-icon"></i>
  </a>
</div>
