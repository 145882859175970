<div app-theme class="nft-modal-container p-15px">
  <div class="w-100 d-flex justify-content-between nft-modal__header">
    <div class="d-flex align-items-center">{{ isSelectingSerialNumber ? "Select an edition" : "Confirm burn" }}</div>
  </div>
  <simple-center-loader *ngIf="loading"></simple-center-loader>
  <div *ngIf="!loading">
    <div *ngIf="filteredSerialNumbers?.length" [ngClass]="{ 'd-none': isSelectingSerialNumber }">
      <div class="fs-15px text-grey5">
        You are about to permanently burn your NFT. <b>This NFT will no longer exist - this action cannot be undone.</b>
      </div>

      <div *ngIf="!globalVars.isMobile()" class="d-flex justify-content-between nft-modal-bid-details">
        <div class="col-2 d-flex flex-column m-16px" style="border-right: 1px solid var(--border)">
          <span>Number</span>
          <span class="mt-5px">#{{ selectedSerialNumber?.SerialNumber }}</span>
        </div>
        <div class="col-5 d-flex flex-column m-16px" style="border-right: 1px solid var(--border)">
          <span>Highest Bid</span>
          <span class="mt-5px">{{ globalVars.nanosToDeSo(highBid) }} DESO (~{{globalVars.nanosToUSD(highBid, 2)}})</span>
        </div>
        <div class="col-5 d-flex flex-column m-16px">
          <span>Min Bid Amount</span>
          <span class="mt-5px">{{ globalVars.nanosToDeSo(selectedSerialNumber?.MinBidAmountNanos) }} DESO (~{{globalVars.nanosToUSD(selectedSerialNumber?.MinBidAmountNanos, 2)}})</span>
        </div>
      </div>

      <div *ngIf="globalVars.isMobile()">
        <div class="nft-modal-bid-details d-flex justify-content-between p-16px">
          <span class="font-weight-bold">Number</span>
          <div>
            <span class="mt-5px">#{{ selectedSerialNumber?.SerialNumber }}</span>
            <a class="fc-blue ml-16px" (click)="goBackToSerialSelection()">Change</a>
          </div>
        </div>
        <div class="nft-modal-bid-details d-flex flex-column justify-content-center p-16px">
          <div class="border-bottom pb-10px d-flex justify-content-between">
            <span class="font-weight-bold">Highest Bid</span>
            <span>
              {{ globalVars.nanosToDeSo(highBid) }} DESO (~{{ globalVars.nanosToUSD(highBid, 2) }})
            </span>
          </div>
          <div class="pt-10px d-flex justify-content-between">
            <span class="font-weight-bold">Min Bid Amount</span>
            <span>
              {{ globalVars.nanosToDeSo(selectedSerialNumber?.MinBidAmountNanos) }} DESO (~{{
                globalVars.nanosToUSD(selectedSerialNumber?.MinBidAmountNanos, 2)
              }})
            </span>
          </div>
        </div>
      </div>

      <!-- Second separator line -->
      <div class="py-16px d-flex align-items-center fs-15px text-grey7">
        <div class="flex-grow-1 nft-modal-separator-2"></div>
      </div>

      <feed-post
        [post]="post"
        [includePaddingOnPost]="true"
        [isParentPostInThread]="true"
        [showLeftSelectedBorder]="false"
        [showInteractionDetails]="false"
        [contentShouldLinkToThread]="false"
      ></feed-post>

      <div *ngFor="let error of errors" class="error-container" style="white-space: pre-line;">
        <i-feather name="alert-circle" class="feather-large"></i-feather>
        {{ error }}
      </div>
      <div
        [ngClass]="{
          'floating-bottom-bar': globalVars.isMobile(),
          'mb-15px': !globalVars.isMobile(),
          'mt-30px': !globalVars.isMobile()
        }"
        class="d-flex align-items-center"
      >
        <button
          class="primary-button font-weight-bold fs-15px br-12px"
          style="height: 48px; width: 140px; line-height: 15px"
          (click)="burnNft()"
          [disabled]="burningNft"
          [class]="{ 'disabled-button': burningNft }"
        >
          {{ burningNft ? "Burning NFT" : "Burn NFT" }}
        </button>
      </div>

    </div>


    <div *ngIf="filteredSerialNumbers?.length" [ngClass]="{'d-none': !isSelectingSerialNumber}">
      <div class="nft-modal__subtitle">Choose the serial number you wish to burn.</div>
      <div class="container fs-15px px-0px">
        <nft-select-serial-number
          [serialNumbers]="filteredSerialNumbers"
          (serialNumberSelected)="selectSerialNumber($event)"
        ></nft-select-serial-number>
      </div>
    </div>
    <div *ngIf="!filteredSerialNumbers.length" class="fs-15px">
      There are no serial numbers available for you to bid on.
    </div>
  </div>
</div>
