import { Component } from "@angular/core";

@Component({
  selector: "creator-profile-page",
  templateUrl: "./creator-profile-page.component.html",
  styleUrls: ["./creator-profile-page.component.scss"],
})
export class CreatorProfilePageComponent {
  constructor() {}
}
