<div class="d-flex w-100 tab-selector__container" style="cursor: pointer; height: 50px" [ngClass]="{ 'tab-selector-links': !buttonSelector }">
  <div
    *ngFor="let tab of tabs"
    (click)="_tabClicked(tab)"
    class="d-flex flex-column align-items-center h-100 pl-15px pr-15px"
  >
    <div class="d-flex h-100 align-items-center">
      <div
        class="d-flex h-100 align-items-center fs-15px"
        *ngIf="tab !== activeTab || !buttonSelector"
        [ngClass]="{ 'active-tab': tab === activeTab, 'fc-secondary': tab !== activeTab }"
      >
        {{ tab | transloco }}
      </div>
      <button
        *ngIf="tab === activeTab && buttonSelector"
      >
        <span>{{ tab | transloco }}</span>
      </button>
      <div class="new-tab-indicator" *ngIf="newTabs.includes(tab) && tab !== activeTab">
        <span>{{ 'tab_selector.new' | transloco }}</span>
      </div>
      <div *ngIf="isLink(tab) && !globalVars.isMobile()" class="d-flex align-items-center ml-5px">
        <i-feather class="feather-small fs-0px" name="external-link"></i-feather>
      </div>
    </div>
  </div>
</div>
