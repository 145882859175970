<div class="message-recipient-modal__container">
  <div
    class="message-recipient-modal__header d-flex justify-content-between align-items-center"
    [ngStyle]="{ 'border-radius': searchHasResults ? '10px 10px 0px 0px' : '10px' }"
  >
    <div class="fc-default"><span>{{ 'messages.select_creator' | transloco }}</span></div>
    <div>
      <!-- Search -->
      <search-bar
        [showCloutavista]="false"
        [isSearchForUsersToMessage]="true"
        [startingSearchText]=""
        (creatorToMessage)="_handleCreatorSelectedInSearch($event)"
        (searchUpdated)="_handleSearchUpdated($event)"
        class="pb-15px border-bottom border-color-grey"
      ></search-bar>
    </div>
  </div>
</div>
