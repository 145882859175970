<app-page>
  <admin
    *ngIf="globalVars.showAdminTools()"
    class="h-100 center-col-width"
  ></admin>
  <div
    *ngIf="!globalVars.showAdminTools()"
    class="fs-15px d-flex flex-column align-items-center global__center__inner"
  >
    <div class="w-100" style="height: 200px"></div>
    You don't have access to this node's admin panel.
  </div>
</app-page>
