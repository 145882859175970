<div *ngIf="post" class="d-flex justify-content-between align-items-center p-16px fc-muted">
  <div [ngClass]="{ 'flex-column': globalVars.isMobile() }" class="d-flex justify-content-around w-100">
    <span [ngClass]="{ 'mb-5px': globalVars.isMobile() }" (click)="openRepostsPage($event)" class="interaction-detail">
      {{ globalVars.abbreviateNumber(post.RepostCount, 0) }}
      {{ "feed_post.repost" | transloco }}{{ post.RepostCount === 1 ? "" : "s" }}
    </span>
    <span (click)="openQuoteRepostsModal($event)" class="interaction-detail">
      {{ globalVars.abbreviateNumber(post.QuoteRepostCount, 0) }}
      {{ "feed_post.quote_repost" | transloco }}{{ post.QuoteRepostCount === 1 ? "" : "s" }}
    </span>
  </div>
  <div [ngClass]="{ 'flex-column': globalVars.isMobile() }" class="d-flex justify-content-around w-100">
    <span [ngClass]="{ 'mb-5px': globalVars.isMobile() }" (click)="openLikesPage($event)" class="interaction-detail">
      {{ globalVars.abbreviateNumber(post.LikeCount, 0) }}
      {{ "feed_post.like" | transloco }}{{ post.LikeCount === 1 ? "" : "s" }}
    </span>
    <span (click)="openDiamondsPage($event)" class="interaction-detail">
      {{ globalVars.abbreviateNumber(post.DiamondCount, 0) }}
      {{ "feed_post.diamond" | transloco }}{{ post.DiamondCount === 1 ? "" : "s" }}
    </span>
  </div>
</div>
