<!--TODO: need loading skeleton ?-->
<div class="buy-deso__container">
  <div class="flex-grow-1">
    <div class="mint-nft__title ml-16px">{{ globalVars.addOwnershipApostrophe(targetUsername) }} followers</div>

    <div class="w-100 border-bottom"></div>
    <tab-selector
      [tabs]="[ManageFollowsComponent.FOLLOWERS, ManageFollowsComponent.FOLLOWING]"
      [activeTab]="activeTab"
      (tabClick)="_handleTabClick($event)"
    ></tab-selector>
    <div class="w-100 border-bottom"></div>
    <simple-center-loader *ngIf="loadingFirstPage"></simple-center-loader>

    <div
      *ngIf="totalFollowerCount == 0 && !datasource.adapter.isLoading"
      class="fs-15px d-flex justify-content-left w-100 p-15px"
    >
      Nobody yet
    </div>
    <div
      #uiScroll
      *uiScroll="let profileEntry of datasource"
      class="row no-gutters px-15px border-bottom fs-15px"
      style="height: 100%"
    >
      <a
        (click)="onRowClicked($event, profileEntry.Username)"
        class="fs-15px d-flex justify-content-left w-100 link--unstyled border-color-grey border-bottom p-15px"
      >
        <!-- Avatar -->
        <div class="manage-follows__avatar-container">
          <a
            [ngClass]="{
              nft__avatar: profileEntry?.ExtraData?.NFTProfilePictureUrl
            }"
            class="manage-follows__avatar br-12px"
            [avatar]="profileEntry.PublicKeyBase58Check"
            [nftProfileUrl]="profileEntry?.ExtraData?.NFTProfilePictureUrl"
            [routerLink]="['/' + globalVars.RouteNames.USER_PREFIX, profileEntry.Username]"
            queryParamsHandling="merge"
          ></a>
        </div>

        <!-- Main Content -->
        <div class="w-100">
          <div class="d-flex align-items-center">
            <!-- Username-->
            <a
              class="fc-default font-weight-bold"
              [routerLink]="['/' + globalVars.RouteNames.USER_PREFIX, profileEntry.Username]"
              queryParamsHandling="merge"
            >
              {{ profileEntry.Username }}
            </a>
            <span *ngIf="profileEntry?.IsVerified" class="ml-1 d-inline-block align-center text-primary"><i class="fas fa-check-circle fa-md align-middle"></i></span>
            <!-- Coin price-->
            <!-- Hidden on mobile, UI gets too crowded otherwise -->
            <a
              style="padding: 3px"
              class="ml-2 fs-13px font-weight-bold px-2 feed-post__coin-price-holder d-lg-block d-none br-12px"
              [routerLink]="AppRoutingModule.buyCreatorPath(profileEntry.Username)"
              queryParamsHandling="merge"
            >
              <span class="fc-default">~{{ globalVars.nanosToUSD(profileEntry.CoinPriceDeSoNanos, 2) }}</span>

              <!-- Buy link -->
              Buy
            </a>

            <!-- Follow button -->
            <div *ngIf="canLoggedInUserFollowTargetPublicKey(profileEntry.PublicKeyBase58Check)" class="ml-auto">
              <follow-button
                [followButtonClasses]="['js-manage-follows__follow-button', 'btn', 'btn-sm', 'btn-outline-primary']"
                [unfollowButtonClasses]="['js-manage-follows__follow-button', 'btn', 'btn-sm', 'btn-primary']"
                [followedPubKeyBase58Check]="profileEntry.PublicKeyBase58Check"
              ></follow-button>
            </div>
          </div>

          <!-- Content -->
          <div
            class="mt-1"
            style="overflow-wrap: anywhere; -ms-word-break: break-all; word-break: break-all; word-break: break-word"
            [innerHTML]="profileEntry.Description | sanitizeAndAutoLink"
          ></div>
        </div>
      </a>
    </div>
    <simple-center-loader *ngIf="loadingNextPage && !loadingFirstPage" [height]="100"></simple-center-loader>
    <!-- Num anonymous followers-->
    <div *ngIf="anonymousFollowerCount > 0" class="fs-15px d-flex justify-content-left w-100 p-15px">
      <!--TODO: pluralize-->
      ... Plus {{ anonymousFollowerCount }} anonymous {{ this.activeTab.toLowerCase() }}
    </div>
    <div class="d-lg-none global__bottom-bar-mobile-height"></div>
    <div class="d-lg-none global__bottom-bar-mobile-height"></div>
    <div class="global__bottom-bar-mobile-height"></div>
    <div class="global__bottom-bar-mobile-height"></div>
  </div>

</div>
