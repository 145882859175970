<div class="fs-16px messages-filter-menu__container">
  <div class="mr-15px ml-15px pt-15px pb-5px font-weight-bold">
    {{ 'messages.custom_inbox_settings' | transloco }}
  </div>

  <div class="w-100 border-color-grey border-bottom"></div>

  <div class="pt-15px pb-15px">
    <span class="messages-filter-menu__title">{{ 'messages.only_show' | transloco }}</span>

    <div class="w-100 d-flex pl-5px mt-15px flex-column messages-filter-menu__checkboxes">
      <div class="mt-5px d-flex flex-row align-items-center">
        <button
          class="checkbox-circle"
          [ngClass]="{ checked: messageFilterHoldsMe }"
          (click)="messageFilterHoldsMe = !messageFilterHoldsMe"
        >
          <i-feather name="check"></i-feather>
        </button>
        <span>{{ 'messages.holders' | transloco }}</span>
      </div>

      <div class="mt-5px d-flex flex-row align-items-center">
        <button
          class="checkbox-circle"
          [ngClass]="{ checked: messageFilterIHold }"
          (click)="messageFilterIHold = !messageFilterIHold"
        >
          <i-feather name="check"></i-feather>
        </button>
        <span>{{ 'messages.holdings' | transloco }}</span>
      </div>

      <div class="mt-5px d-flex flex-row align-items-center">
        <button
          class="checkbox-circle"
          [ngClass]="{ checked: messageFilterFollowingMe }"
          (click)="messageFilterFollowingMe = !messageFilterFollowingMe"
        >
          <i-feather name="check"></i-feather>
        </button>
        <span>{{ 'messages.followers' | transloco }}</span>
      </div>

      <div class="mt-5px d-flex flex-row align-items-center">
        <button
          class="checkbox-circle"
          [ngClass]="{ checked: messageFilterIFollow }"
          (click)="messageFilterIFollow = !messageFilterIFollow"
        >
          <i-feather name="check"></i-feather>
        </button>
        <span>{{ 'messages.following' | transloco }}</span>
      </div>
    </div>
  </div>
  <div class="w-100 border-color-grey border-bottom"></div>
  <div class="mt-15px">
    <span class="messages-filter-menu__title">{{ 'messages.order_by' | transloco }}</span>
    <div class="w-100 mt-15px">
      <div class="btn-group w-90" dropdown>
        <button
          id="button-basic"
          dropdownToggle
          type="button"
          class="btn messages-filter-menu__dropdown fc-secondary"
          aria-controls="dropdown-basic"
        >
          {{ sortAlgorithmToText() }}
          <i-feather name="chevron-down" class="feather-small position-absolute" style="right: 12px;"></i-feather>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem"><a class="dropdown-item fs-16px" (click)="setSortAlgorithm('time')">{{ 'messages.most_recent' | transloco }}</a></li>
          <li role="menuitem"><a class="dropdown-item fs-16px" (click)="setSortAlgorithm('deso')">{{ 'messages.most_deso' | transloco }}</a></li>
          <li role="menuitem">
            <a class="dropdown-item fs-16px" (click)="setSortAlgorithm('followers')">{{ 'messages.most_followed' | transloco }}</a>
          </li>
          <li role="menuitem">
            <a class="dropdown-item fs-16px" (click)="setSortAlgorithm('holders')">{{ 'messages.largest_holder' | transloco }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="d-flex flex-center w-100 pb-15px">
    <div class="w-100 messages-filter-menu__buttons">
      <button
        (click)="updateGlobalMessagesPreferences()"
        class="btn"
      >
      {{ 'messages.update' | transloco }}
      </button>
      <a (click)="closePopUp()">{{ 'messages.cancel' | transloco }}</a>
    </div>
  </div>
</div>
