<div class="flex-grow-1">
  <div class="buy-deso__container">
    <div class="px-16px pb-16px">
      <div class="d-flex w-100 justify-content-between">
        <div class="mint-nft__title">{{ 'trends.trends' | transloco }}</div>
        <div class="d-flex flex-column align-items-end">
          <div
            class="d-flex"
            dropdown
            (click)="$event.stopPropagation()"
            container="body"
            style="align-items: center"
          >
            <button class="trend-dropdown-button d-flex align-content-between" role="button" dropdownToggle>
              <span>{{ activeTab | transloco }}</span>
              <i-feather name="chevron-down" class="feather-small"></i-feather>
            </button>
            <div class="dropdown-menu dropdown-menu-right p-0 bc-dropdown-menu buy-deso__currency-dropdown" *dropdownMenu>
              <div>
                <a
                  *ngFor="let tab of availableTabs"
                  class="dropdown-menu-item fs-12px d-block link--unstyled p-10px feed-post__dropdown-menu-item"
                  (click)="selectTab(tab)"
                >
                  {{ tab | transloco }}
                </a>
              </div>
            </div>
          </div>
          <div *ngIf="activeRightTabOption.poweredBy" class="py-10px text-grey5">
            <a class="link--unstyled fs-14px text-grey7" [href]="activeRightTabOption.poweredBy.link" target="_blank">
              <i class="fas fa-external-link-alt"></i>
              {{ 'trends.powered_by' | transloco }} {{ activeRightTabOption.poweredBy.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <simple-center-loader *ngIf="isLoadingProfilesForFirstTime"></simple-center-loader>
      <div class="viewport disable-scrollbars" [ngClass]="{ 'trends-scroll-container': !isModal }">
        <div *ngIf="activeTab === RightBarCreatorsComponent.HASHTAGS.name">
          <a
            *ngFor="let hashTag of hashtagLeaderboard"
            (click)="navigateToHashtag(hashTag.Hashtag)"
            queryParamsHandling="merge"
            class="link--unstyled d-flex align-items-center border-bottom"
            style="height: 50px"
          >
            <div class="trend-holder d-flex align-items-center">
              <div>
                <div class="d-flex">
                  <div class="trend-name" style="flex-grow: 1">
                    <span>{{ hashTag.Hashtag }}</span>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-column trend-end" style="align-items: flex-end">
                <div class="text-grey5 fs-15px">
                  {{ hashTag.Count }}
                </div>
              </div>
            </div>
          </a>
        </div>
        <div *ngIf="activeTab !== RightBarCreatorsComponent.HASHTAGS.name">
          <div #uiScroll *uiScroll="let profileEntryResponse of datasource" class="row no-gutters px-15px border-bottom fs-15px">
            <div class="row no-gutters px-15px border-bottom fs-15px w-100"
                 *ngIf="profileEntryResponse.Profile && !profileEntryResponse.User.IsBlacklisted && !profileEntryResponse.User.IsGraylisted"
            >
              <a
                [routerLink]="['/' + globalVars.RouteNames.USER_PREFIX, profileEntryResponse.Profile.Username]"
                queryParamsHandling="merge"
                class="col mb-0 py-15px link--unstyled d-flex align-items-center"
              >
                <div
                  [ngClass]="{
                    nft__avatar: profileEntryResponse?.ExtraData?.NFTProfilePictureUrl
                  }"
                  class="creators-leaderboard__avatar d-inline-block align-top rounded"
                  [avatar]="profileEntryResponse.Profile.PublicKeyBase58Check"
                  [nftProfileUrl]="profileEntryResponse?.ExtraData?.NFTProfilePictureUrl"
                ></div>

                <div style="flex-grow: 1" class="mt-2 ml-3 fc-default">
                  <span>{{ profileEntryResponse.Profile.Username }}</span>
                  <span
                    *ngIf="profileEntryResponse.Profile.IsReserved && !profileEntryResponse.Profile.IsVerified"
                    class="d-inline-block ml-1 cursor-pointer lh-12px fc-muted"
                    matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
                    [matTooltip]="'This profile is reserved. Click to learn how to claim it.'"
                  >
              <i class="far fa-clock fa-md"></i>
            </span>
                  <span
                    *ngIf="profileEntryResponse.Profile.IsVerified"
                    (click)="tooltip.toggle()"
                    class="ml-1 cursor-pointer text-primary"
                    matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
                    [matTooltip]="'This account is verified'"
                    #tooltip="matTooltip"
                  >
              <i class="fas fa-check-circle fa-md"></i>
            </span>
                </div>

                <div class="d-flex flex-column" style="align-items: flex-end">
                  <div class="fc-green" *ngIf="activeTab === RightBarCreatorsComponent.GAINERS.name">
                    +${{
                    globalVars.abbreviateNumber(globalVars.nanosToUSDNumber(profileEntryResponse.DeSoLockedGained), 2)
                    }}
                  </div>
                  <div class="fc-blue" *ngIf="activeTab === RightBarCreatorsComponent.DIAMONDS.name">
                    {{ globalVars.abbreviateNumber(profileEntryResponse.DiamondsReceived, 0) }}
                    <i class="icon-diamond"></i>
                  </div>
                  <div
                    [ngClass]="{
                'text-grey7':
                  activeTab !== RightBarCreatorsComponent.COMMUNITY.name &&
                  activeTab !== RightBarCreatorsComponent.ALL_TIME.name,
                'text-grey5':
                  activeTab === RightBarCreatorsComponent.COMMUNITY.name ||
                  activeTab === RightBarCreatorsComponent.ALL_TIME.name
              }"
                    [ngStyle]="{
                'font-size':
                  activeTab === RightBarCreatorsComponent.COMMUNITY.name ||
                  activeTab === RightBarCreatorsComponent.ALL_TIME.name
                    ? '15px'
                    : '10px'
              }"
                  >
                    ~{{ globalVars.nanosToUSD(profileEntryResponse.Profile.CoinPriceDeSoNanos, 2) }}
                    <i
                      class="fas fa-external-link-alt"
                      *ngIf="activeTab === RightBarCreatorsComponent.COMMUNITY.name"
                      [href]="profileEntryResponse.BithuntProject.website_url"
                    ></i>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <!-- Creator rows -->
      </div>
      <!-- Bottom spacer for PWA apps-->
      <div *ngIf="globalVars.windowIsPWA() && globalVars.isMobile()" class="mt-20px"></div>
      <div *ngIf="globalVars.isMobile()" class="global__bottom-bar-mobile-height"></div>
    </div>
  </div>
</div>
